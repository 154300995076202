import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ErrorMessage } from '../styled/ErrorMessage';
import { useTranslation } from "react-i18next";
import { fetchLanguages } from "../context/LanguageUtility";
import { PasswordInputContainerNoCover, PasswordToggleIconNoCover } from "../styled/PasswordInput";
import { MdRefresh } from "react-icons/md";
import MultiSelectInput from "./MultiSelectInput";

const { getRandomInteraction } = require('../context/ConversationScenarios');

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;

const StyledLabel = styled.label`
    font-size: 0.8em;
    margin-bottom: 0.5em;
    //display: flex;
    align-items: center;
    gap: 1em;
  color:var(--blue-gradient);
`;

const StyledCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 1em; 
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledInput = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const StyledButton = styled.button`
  max-width: 150px;           // Limit the button width
  padding: 10px 20px;         // Add some horizontal padding
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
  border: 4px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
`;

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const ModalContentTemp = styled.div`
    background: #fff;
    padding: 2em;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    position: relative;
    color: #000;
`;

const ButtonContainer = styled.div`
    display: flex;              
    gap: 10px;                  
    justify-content: center;    // Center the buttons horizontally
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.7em;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
  -webkit-appearance: none;

      @media (max-width: 768px) {
        padding: 1.1em; // Increase padding for mobile
      }
`;

const InputTitle = styled.div`
  font-size:1.5em;
  margin-bottom:10px;
`;

const RoomForm = ({ isOpen, onNewRoom, onClose }) => {
    const [name, setName] = useState("");
    //const [description, setDescription] = useState("");
    const [language, setLanguage] = useState("default");
    const [isLanguageEnabled, setIsLanguageEnabled] = useState(false);
    const [customLanguage, setCustomLanguage] = useState(""); 
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [languageConfiguration, setLanguageConfiguration] = useState(null);
    //const [selectedLanguages, setSelectedLanguages] = useState([]);
    
    const { t } = useTranslation();
    
    //const supportedLanguages = Object.keys(languageConfig).sort();
    useEffect(() => {
        fetchLanguages(setSupportedLanguages, setLanguageConfiguration);
        // generate random name as default
        setName(getRandomInteraction()); 
    }, []);

    // const handleSelectLanguages = (languages) => {
    //     setSelectedLanguages(languages);
    // };
    
    // If user enter chooses other language, check that entered value is a valid language
    const checkCustomLanguage = async (language) => {
        
        // 1. call webserver endpoint to do validation 
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/lang/c`, {customLanguage:language}, { withCredentials: true });
        
        // if not valid, update error message appropriately
        if(!response.data.success){
            if(response.data.suggestion) {
                setErrorMessage(`Did you mean '${response.data.suggestion}'?`);
            }else{
                setErrorMessage("Sorry we don't recognize that as a language.");
            }
        }
        
        return response.data.success;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        
        // Only validate language if it's enabled
        if (isLanguageEnabled && language === "default") {
            setErrorMessage("Please select a language.");
            setIsSubmitting(false);
            return;
        }
        
        // check if custom language is valid
        if(language === 'Other'){
            try{
                const isCustomLanguageValid = await checkCustomLanguage(customLanguage);
                if(!isCustomLanguageValid){
                    setIsSubmitting(false);
                    return;
                }
            }catch(error){
                console.log(error);
                setErrorMessage("Sorry we don't recognize that as a language.");
                setIsSubmitting(false);
                return;
            }
        }
        
        const newRoom = {
            name: name,
            description: '',
            withBot: isLanguageEnabled, // Add bot only if language is enabled
            // If 'Other' is selected and the language option is enabled, use the value from customLanguage
            // Otherwise, use the selected language or undefined if the language option is not enabled
            botLang: isLanguageEnabled ? (language === 'Other' ? (customLanguage.charAt(0).toUpperCase() + customLanguage.slice(1)) : language) : undefined
        };
        
        //console.log(newRoom);

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/rooms`, newRoom, { withCredentials: true });
            if(response.data.success) {
                
                //console.log(response.data.room);
                
                onNewRoom(response.data.room);

                setName(getRandomInteraction());
                //setDescription("");
                
                setIsSubmitting(false); 
                clearForm();

            } else {
                setErrorMessage(response.data.error);
                setIsSubmitting(false); 
            }
        } catch (err) {
            console.log(err);
            setErrorMessage("Something went wrong, unable to create conversation.");
            setIsSubmitting(false); 
        } 
    };

    const handleCustomLanguageChange = (e) => {
        const value = e.target.value;
        setCustomLanguage(value);
    };
    
    const handleSupportedLanguageChange = (e) => {
        setLanguage(e.target.value);
        setErrorMessage("");
    }
    
    const clearForm = () => {
        setName(getRandomInteraction());
        setLanguage("default");
        setIsLanguageEnabled(false);
        setCustomLanguage("");
        setErrorMessage("");

        onClose();
    }

    const toggleScenarioName = () => {
        setName(getRandomInteraction());
    }

    return (

        isOpen && (
            <ModalBackground onClick={onClose}>
                <ModalContentTemp onClick={e => e.stopPropagation()}>
                    {isSubmitting && <SpinnerOverlay><Spinner /></SpinnerOverlay>}
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage> }
                    <StyledForm onSubmit={handleSubmit}>
                        
                        <StyledLabel>
                            <InputTitle>{t('StartBabblingAbout')}</InputTitle>
                            <PasswordInputContainerNoCover>
                                <StyledInput type="text" placeholder={t('RoomFormName')} value={name} onChange={e => setName(e.target.value)} required maxLength={50} />
                                <PasswordToggleIconNoCover onClick={toggleScenarioName}>
                                    <MdRefresh />
                                </PasswordToggleIconNoCover>
                            </PasswordInputContainerNoCover>
                        </StyledLabel>
                        
                        {/*<StyledLabel>*/}
                        {/*    <MultiSelectInput */}
                        {/*        onSelectLanguages={handleSelectLanguages} */}
                        {/*        supportedLanguages={supportedLanguages}*/}
                        {/*    />*/}
                        {/*</StyledLabel>*/}
                        
                        <StyledLabel />
                        <StyledLabel>
                            <StyledCheckboxLabel>
                                <StyledCheckbox
                                    checked={isLanguageEnabled}
                                    onChange={e => setIsLanguageEnabled(e.target.checked)}
                                />
                                <span>{t('RoomFormAddBab')}</span>
                            </StyledCheckboxLabel>
                        </StyledLabel>
                        <StyledLabel>
                            <span><i>{t('RoomFormBabDesc')}</i></span>
                        </StyledLabel>

                        {isLanguageEnabled && (
                            <StyledLabel>
                                <Select
                                    value={language}
                                    onChange={e => handleSupportedLanguageChange(e)}
                                    disabled={!isLanguageEnabled}
                                >
                                    <option value="default">{t('LanguageSelectDefault')}</option>
                                    {supportedLanguages.map((lang, index) => (
                                        <option key={index} value={lang}>
                                            {t(lang)}
                                        </option>
                                    ))}
                                    <option value="Other">Other</option>
                                </Select>
                            </StyledLabel>
                        )}
                        
                        {(language === 'Other' && isLanguageEnabled) && (
                            <StyledLabel>
                                <StyledInput
                                    type="text"
                                    placeholder={t('SpecifyLanguage')}
                                    value={customLanguage}
                                    onChange={e => handleCustomLanguageChange(e)}
                                    required={language === 'Other'} // Only required when 'Other' is selected
                                />
                            </StyledLabel>
                        )}

                        <ButtonContainer>
                            <StyledButton onClick={clearForm}>{t('CancelButton')}</StyledButton>
                            <StyledButton type="submit">{t('RoomCreateButton')}</StyledButton>
                        </ButtonContainer>
                        
                    </StyledForm>
                    
                </ModalContentTemp>
            </ModalBackground>
        )
    );
};

export default RoomForm;
