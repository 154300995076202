import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ScaleLoader, BounceLoader, BeatLoader } from 'react-spinners';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);  // Adjusted color to a lighter shade
    border-radius: 50%;
    border-top: 3px solid #323232;  // Adjusted color to match a generic dark text
    width: 20px; 
    height: 20px;
    animation: ${spin} 1s linear infinite;
    margin-right: 15px;  // Increased margin for better spacing
`;

const SendingMessageContainer = styled.div`
    width: 100%;
    text-align: left;  // Aligned text to the left
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: #3c95f4; //#f5f5f5;  // Adjusted background color to a lighter shade
    //background-color: f1f1f4;
    //border-radius: 5px;
    display: flex;
    align-items: center;  // Only center vertically since text-align is now left
    font-size: 0.8em;  // You can adjust this to your app's font size
    color:white;
`;

const SomeoneTyping = ({message}) => {
    
    return (
        <SendingMessageContainer>
            <BeatLoader size={10} color='white' />
            { message } is typing ...
        </SendingMessageContainer>
    );
};

export default SomeoneTyping;
