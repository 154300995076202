import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useChat } from '../context/ChatProvider';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LoadingScreen from "./LoadingScreen";
import { languageConfig } from '../context/LanguageConfig';
import LineLoginButton from "./LineLoginButton";
import { GoogleLogin } from '@react-oauth/google';

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //height: 100vh;
    text-align: center;
  margin-top:2.5em;
  //width:85%;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 3rem;
        color:white;
      }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid #ddd;
    position: relative;
  width:85%;
  
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        //background: var(--blue-active-color);
        transition: .3s ease-in-out opacity, box-shadow;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
    }
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  background-color: #f3f3f3;
  width:100%;
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: #FF5C5C;  // Reddish color
    margin: 0;  // Prevents changing the size of the form
`;

const Button = styled.button`
  //margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const Header = styled.div`
  display:flex;
  align-content: center;
  font-size: 0.8em;
  //padding: 15px 20px 10px 20px;
  //width:250px;
  //margin-bottom: 5px;
  //border-bottom: 1px solid #fff;
  
  width:32%;

  @media (max-width: 768px) {
    width:95%;
  }
  
`;

const LoginSeperator = styled.div`
  color:dimgray;
  font-size: 1em;
  text-decoration: solid;
`;

// Add a utility function to parse query parameters
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Utility function to find language name by loc
function findLanguageNameByLoc(loc, languageConfig) {
    for (const [languageName, config] of Object.entries(languageConfig)) {
      if (config.loc === loc) {
        return languageName;
      }
    }
    return null; 
}

const InviteLanding = () => {

    const query = useQuery();
    const initialLoc = query.get('lang') || 'en';  // 'en' as default language
    const initialLangName = query.get('full') || 'English';
    //const initialLangName = findLanguageNameByLoc(initialLoc, languageConfig);
  
    const { i18n } = useTranslation();  // Added i18n
    const { socket, user, setUser, setToken, supportedLanguages, fetchRooms, joinRoom, rooms, setRooms } = useChat();

    useEffect(() => {
        i18n.changeLanguage(initialLoc);  // Change the language
    }, [initialLoc, i18n]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const [inviteValid, setInviteValid] = useState(false);
    const [roomId, setRoomId] = useState(null);
    const [token, setInviteToken] = useState(null);
    const [inviteBy, setInvitedBy] = useState(null);
    const [isLoading, setIsLoading] = useState(false); 
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showLineJoin, setShowLineJoin] = useState(false);
    const [showAuthContinue, setShowAuthContinue] = useState(false);
    const [showLineJoinSuccess, setShowLineJoinSuccess] = useState(false);

    const { t } = useTranslation();

    const { inviteId } = useParams();

    // run this on page load
    useEffect(() => {
        setIsLoading(true);
        validateInvite();
        setIsLoading(false);
    }, []);
    

    const validateInvite = async () => {
        try{
            // this should return a user as well - as we will create temporary 'guest' user account ...
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/invite/${inviteId}`, { withCredentials: true });
                        
            const { roomId, room, invitedBy, authUser, token } = response.data;
            
            if (roomId) {
                setInviteValid(true); // show form to allow user to enter a username and language
                setInvitedBy(invitedBy);
                setRoomId(roomId);
                
                //setRooms([...rooms, room])
                //console.log(roomId);
                // setInviteToken(token);
                
                // if user is already authenticated ..
                if(authUser && token){
                    // Note : socket connection is made in ChatProvider once Token and User is set 
                    setUser(authUser);
                    setToken(token);
                    
                    setShowAuthContinue(true);
                    
                    if(authUser.lineUserId){
                        setShowLineJoin(true);
                    }
                }
                
                fetchRooms(); // ensure we populate rooms so that they can be found when joining clientside

                // Note : socket connection is made in ChatProvider once Token and User is set 
                //setUser(guestUser);
                //setToken(token);

            } else {
                setErrorMessage(t('InviteLandingePageInvalid'));
                setIsError(true);
                setInviteValid(false);
            }
        }catch(error){
            console.log(error);
            setErrorMessage(error);
            setIsError(true);
            setInviteValid(false);
        }
    }

    const startPolling = async (guestUser) => {
        let intervalId = setInterval(async () => {
            try {
                
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/users/${guestUser.id}/avatar`, { withCredentials: true });
                
                if (response.data.success && response.data.status === 'done') {
                    
                    guestUser.avatar = response.data.avatar;
                    setUser(guestUser);

                    joinRoom(roomId);
                
                    setIsLoading(false);
                    
                    clearInterval(intervalId);

                    navigate('/chat');
                }
            } catch (error) {
                console.error('Error fetching room image:', error);
                clearInterval(intervalId);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    };

    // calls a server endpoint that sends a join message to user on line
    // adds link to conversation using user.lineUserId
    // sends a message that they have joined babble x
    const handleJoinWithLine = async () => {
        
        try{
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/line/join`, { roomId:roomId }, { withCredentials: true });
            
            const { success } = response.data;
            
            if(success){
                setShowLineJoinSuccess(true);
                // inform others in babbleon chat of user joining
                if(socket){
                    socket.emit('line-user-added',{ roomId: roomId, user:user });
                }
            }
            
        }catch(error){
            setErrorMessage("Problem joining from LINE");
        }
        
    };
    
    // handles joining room for already authed user
    const handleJoinRoom = async () => {
        //console.log(`joining room ${roomId}`);
        
        // TODO Ensure that room is added to the users invited rooms list so they have access later
        // call server endpoint to do this ? or emit event?
        // socket.emit('add-invite-room',{ userId:user.id, roomId:roomId });
        
        joinRoom(roomId);
        
        setIsLoading(false);
        navigate('/chat');
    };
    
    // This is bascially joining the conversation as guest
    // Can also join with LINE if authed LINE user
    const onSubmit = async (data) => {
        const { username, language } = data;
        
        setIsLoading(true);
        
        try{
            // create a guest account and join babble
            // endpoit will
            // create guest user account 
            // create jwt token
            // return both
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/guest`, { username: username, language: initialLangName, inviteId:inviteId }, { withCredentials: true });

            const { guest, token, success, error } = response.data;

            if(success){
                
                setToken(token);
                setUser(guest);
                
                // // give user access to conversation
                await fetchRooms();
                
                console.log('Navigating to room ', roomId);
                navigate(`/guest-invite?roomId=${roomId}`);

            }else{
                setErrorMessage(error);
                setIsError(true);
                setInviteValid(false);
            }

        }catch(err){
            console.log(err);
            setErrorMessage("Sorry, there was a problem processing your invitation.");
            setIsError(true);
            setIsLoading(false);
        }
    };

    const googleSuccess = async (response) => {
        try {
            const idToken = response.credential; // Extract the ID token from Google response

            //console.log(response);
            //console.log('Google success! token=', idToken);

            // Send the ID token to your backend for verification
            const backendResponse = await axios.post(`${process.env.REACT_APP_SERVER}/v/gtoken`,
                { idToken: idToken },
                { withCredentials: true }
            );

            const { token, user } = backendResponse.data;

            if (token && user) {
                setToken(token);
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', JSON.stringify(token));
                sessionStorage.setItem('userLoggedInWithGoogle', "TRUE");
                //setErrorMessage(null);
                // NOTE : socket.io connection is made in ChatProvider once User and Token is set

                // Check for last room and join
                // if(user.latestRoom){
                //     joinRoom(user.latestRoom.id);
                // }

                await handleJoinRoom();
                
            } else {
                // Handle the case where the ID token is not verified
                console.error("Invalid Google ID token / Unable to authenticate");
                setErrorMessage("Failed to login with Google.")
            }
        } catch (error) {
            //console.error("Error handling Google login", error);
            setErrorMessage("Failed to login with Google.")
        }
    };
    const googleError = (response) => {
        //console.error("Google Login Error", response);
        setErrorMessage("Failed to login with Google.")
    };
    
    return (
        <>
        { (isLoading && !inviteValid) ?
            <LoadingScreen />
        :
        <SignupContainer>
            <h1>{t('HomepageTitle')}</h1>
            <FormContainer>
                { isError && <ErrorText>{errorMessage}</ErrorText> }
                
                { <p><strong>{inviteBy}</strong> {t('InviteLandingPageInvitedBy')}</p> }

                { showAuthContinue ? 
                    <>
                    <Button onClick={handleJoinRoom}>{t('ContinueButton')}</Button>
                    { showLineJoin && <LineLoginButton text={`${t('ContinueButton')} LINE`} onInit={handleJoinWithLine} /> }
                    { showLineJoinSuccess && <p>${t('LineJoinSuccess')}</p>}
                    </>
                    :
                    <>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            {...register("username", { 
                                required: true,
                                pattern: /^[a-zA-Z0-9_]+$/  
                            })}
                            placeholder={t('SignupUsernameText')}
                        />
                        {errors.username && <ErrorText>{t('SignupUsernameValidate')}</ErrorText>}
                        <Button type="submit">{t('JoinChatButton')}</Button>
                    </Form>
                    <LoginSeperator>- OR -</LoginSeperator>
                    <Header>
                        <LineLoginButton text={t('LineSignIn') } extraState={`inviteId=${inviteId}`} />
                    </Header>
                    <Header>
                        {/* Google Login Button */}
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                            buttonText={t('GoogleSignin')}
                            width="250"
                            height="45"
                            onSuccess={googleSuccess}
                            onError={googleError}
                            cookiePolicy={'single_host_origin'}
                        />
                    </Header>
                    </>
                }
                
            </FormContainer>
        </SignupContainer>
        }
        </>
    );
};

export default InviteLanding;