import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Description } from '../styled/Description';
import { DeleteButton } from '../styled/DeleteButton';
import axios from 'axios';
import { BeatLoader, ClipLoader } from 'react-spinners';

const RoomItemStyled = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;  
    gap: 1vw;
    width: 100%;
    padding: var(--space) var(--horizontal-space);
    list-style: none;
    background: ${props => props.active ? 'var(--blue-active-color)' : 'transparent'};
    //cursor: pointer;
    transition: all .05s;

    &:hover {
        background: var(--blue-active-color);
    }

    & span {
        font-weight: 500;
        font-size: 1em;
    }

    pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
    cursor: ${props => props.isLoading ? 'default' : 'pointer'};
`;

const RoomItemTopSection = styled.div`
    display: flex;
    gap: 1vw;
    //align-items: center;
    width: 100%;
  margin-left:5px;
`;

const RoomItemImage = styled.img`
    height: 3vw;
    width: 3vw;
    border-radius: 20px;
    object-fit: cover;
    flex-shrink: 0;
`;

const RoomItemDescription = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const UnreadCountBadge = styled.div`
    position: absolute;
    top: 0px;
    right: 90%;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.8em;
    font-size: 0.8em;
    font-weight: bold;
`;


const RoomItem = ({ room, isActive, handleClick, showDeleteIcon, handleDelete, unreadMessagesCount, updateRoomImage }) => {
    const { id, name, image, description } = room;
    const [isLoading, setIsLoading] = useState(!image);

    // polling for image (this should only happen for newly created rooms in UI)
    useEffect(() => {
        if (!image) {
            startPolling();
        }
    }, []);

    const startPolling = async () => {
        let intervalId = setInterval(async () => {
            try {
                // TODO: should only poll if user is logged in ...

                const response = await axios.get(`${process.env.REACT_APP_SERVER}/rooms/${id}/status`, { withCredentials: true });
                
                if (response.data.success && response.data.status === 'completed') {
                    
                    updateRoomImage(id, response.data.image);

                    setIsLoading(false);
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error fetching room image:', error);
                clearInterval(intervalId);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    };

    return (
        <RoomItemStyled active={isActive} onClick={() => handleClick(id)} isLoading={isLoading}>
            {unreadMessagesCount > 0 && <UnreadCountBadge>{unreadMessagesCount}</UnreadCountBadge>}
            <RoomItemTopSection>
                {/*{isLoading */}
                {/*        ? <ClipLoader size='25' color='#ffffff' /> */}
                {/*        : <RoomItemImage alt='room-img' src={image} />}*/}
                <RoomItemDescription>
                    <span>{name}</span>
                    <Description color='rgba(254,254,254,0.5)' size='0.7em'>{description}</Description>
                </RoomItemDescription>
                {showDeleteIcon && <DeleteButton onClick={(e) => { e.stopPropagation(); handleDelete(id, name); }} />}
            </RoomItemTopSection>
            
        </RoomItemStyled>
    );
};

export default RoomItem;
