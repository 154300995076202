import React, {useState} from 'react';
import styled from 'styled-components';
import LoginModal from './LoginModal';
import TopBarNav from '../styled/Topbar';
import { useTranslation } from "react-i18next";
import { AiFillWechat } from "react-icons/ai"
import {useChat} from "../context/ChatProvider";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const WrapperContainer = styled.div`
  display: grid;
  height: 100vh;
  place-items: center;
`;

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  height: 100vh;
  color: white;
  text-align: center;
  padding: 0 1em;
  margin-top: 6em;
  
  h1 {
    font-size: 2.5rem;
    //margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
`;

const Button = styled.a`
  //width: 100%;  // Full width to match input fields
  padding: 20px 20px;  // Remove horizontal padding to match input fields width
  border-radius: 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-size:1.3em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;  // Display inner elements in a row
  justify-content: center;  // Center inner elements horizontally
  align-items: center;  // Center inner elements vertically

  width: clamp(290px, 18vw, 20%);
  margin-bottom:20px;
  margin-top:45px;
  
  &:hover {
    background-color: #0056b3;
  }

  & img {
    padding:20px;
  }
`;

const ButtonSvgContainer = styled.div`
  display: flex;
  //flex-direction:column;
  align-items: flex-end;
  justify-content: space-between;
  width: fit-content;
  //margin-right:15em;
  margin-bottom:3em;
`;

const FullLogoContainer = styled.div`
  display:flex;
  flex-direction:row;

  h1 {
    font-size: 2.2rem;
    position:absolute;
    margin-left:48px;
    margin-top:0.3em;
    //margin-bottom: 1rem;
  }
`;

const NewLogoContainer = styled.div`
  display:flex;
  flex-direction:row;
  margin-right:7.5em;
`;

const ImageContainer = styled.img`
  width:4.1em;
  height: 4.1em;
  border-radius: 50%;
  border:solid;
`;

const SvgImage = styled.img`
  //display: block;
  //margin-right: 3em;
  width: 90px; // Adjust size as needed
  //height: auto;
  //margin-top: 1rem; // Space below the title
  margin-bottom: 3.3rem;
  margin-top:3.5em;
`;

const ChatIcon = styled.div`
  margin-bottom:1em;
  margin-left:7.5em;
`;

const HomepagePhraseStyled = styled.p`
  max-width: 30em; // Adjust this value as needed
  overflow-wrap: break-word;
`;

const LinePowered = styled.div`
  width:10px;
  height:10px;
  
  & img {
    width:21px;
    height:18px;
    padding-left:3px;
  }
`;

const SignInLink = styled.div`
    cursor:pointer;
    font-size:0.8em;
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Homepage = () => {
    
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const {setUser, setToken, setCurrentRoom, setInitJoin, setActiveView, setIsNavOpen, resetContextFor} = useChat();
  const [startClicked, setStartClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');
    
  // ensure we are at top when homepage loads
  window.scrollTo(0, 0);

  const handleInputChange = (event) => {
        setInputValue(event.target.value);
  };
    
  const handleBabbleClick = async () => {
      setStartClicked(true);
      try{
          // check for existing logged in user
          const response = await axios.get(`${process.env.REACT_APP_SERVER}/init-chat`, { withCredentials: true });

          const { room, user, token, success} = response.data;

          setToken(token);
          resetContextFor(user);
          
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('userLoggedInWithGoogle', "FALSE");

          setCurrentRoom(room);
          // setting this so it can be checked on reconnect to auto join room user was in when disconnected ..
          sessionStorage.setItem('currentRoomId', room.id);
          sessionStorage.setItem('currentRoom', JSON.stringify(room));

          setInitJoin(room.id);

          setActiveView("chat");
          setIsNavOpen(false);
          
          navigate('/init-chat')
          //navigate('/chat');

      }catch(error){
          console.log(error);
          setStartClicked(false);
      }  
  };
  
  return (
    <WrapperContainer>
      <TopBarNav onSignInClick={()=> setShowModal(true)} />
      <HomePageContainer>

          <ButtonSvgContainer>
              {/*<h1>{t('HomepageTitle')} <AiFillWechat size='0.8em' /></h1>*/}
              <FullLogoContainer>
                  <NewLogoContainer>
                      <ImageContainer src={"/babblers/BabbleonLogo.png"} />
                  </NewLogoContainer>
                  <h1>abbleOn Chat</h1>
                  {/*<SvgImage src={"./powered-by-openai-badge.svg"} alt="Powered by OpenAI" />*/}
                  
              </FullLogoContainer>
              <ChatIcon><AiFillWechat size='3em' /></ChatIcon>
          </ButtonSvgContainer>
          
          <HomepagePhraseStyled>{t('HomepagePhrase')}</HomepagePhraseStyled>
          <HomepagePhraseStyled>{t('HomepagePhrase2')}</HomepagePhraseStyled>
          
          {/*<Input */}
          {/*    type="text" */}
          {/*    inputMode="text" */}
          {/*    placeholder="Type a word or phrase you want to translate" */}
          {/*    maxLength={70}*/}
          {/*    onChange={handleInputChange}*/}
          {/*></Input>*/}
          <Button onClick={handleBabbleClick}>
              {startClicked ? <BeatLoader size={10} color="white" /> : t('SignupButton')}
          </Button>
          
          {/*<Button onClick={()=> setShowModal(true)}>{t('SignupButton')}</Button>*/}
          {/*- {t('HomepageRegisterText')} -*/}
      
          {/*<FullLogoContainer>*/}
          {/*    <NewLogoContainer>*/}
          {/*        <ImageContainer src={"/babblers/BabbleonLogo.png"} />*/}
          {/*    </NewLogoContainer>*/}
          {/*    <h1>abbleOn Chat</h1>*/}
          {/*</FullLogoContainer>*/}
          
          <SignInLink onClick={() => navigate('/signup')}>Don't have an account? Sign Up</SignInLink>
          
          {showModal && <LoginModal onClose={() => setShowModal(false)} />}
          
      </HomePageContainer>
    </WrapperContainer>
  );
};

export default Homepage;
