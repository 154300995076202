import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;              // Added this line to make use of flexbox.
    justify-content: center;    // Center content horizontally.
    align-items: center;        // Center content vertically.
    z-index: 1000;
`;

const ModalContent = styled.div`
    width: 80%;                 
    max-width: 600px;           
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;         
    display: flex;              
    flex-direction: column;    // Stack children vertically
    gap: 20px;                 // Gap between message and buttons
    color: #000;
    align-items: center;

    & > p {
        margin: 0;              
    }
`;

const ButtonContainer = styled.div`
    display: flex;              
    gap: 10px;                  
    justify-content: center;    // Center the buttons horizontally
`;

const Button = styled.button`
  max-width: 150px;           // Limit the button width
  padding: 10px 20px;         // Add some horizontal padding
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ConfirmationModal = ({ onClose, onConfirm, message }) => {
    const { t } = useTranslation();

    return (
    <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
            <p>{message}</p>
            <ButtonContainer>
                <Button onClick={onConfirm}>{t('ConfirmButton')}</Button>
                <Button onClick={onClose}>{t('ConfirmButtonNo')}</Button>
            </ButtonContainer>
        </ModalContent>
    </ModalOverlay>
)};

export default ConfirmationModal;
