import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { languageConfig } from '../context/LanguageConfig';
import { useTranslation } from "react-i18next";
import TopBarNav from '../styled/Topbar';

const baseUrl = process.env.REACT_APP_SERVER;

const SignupContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid #ddd;
    position: relative;
    width: 50em;

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 40px;
        border: none;
        border-radius: 5px;
        background: var(--blue-active-color);
        transition: .3s ease-in-out opacity, box-shadow;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
    }
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 70px 20px 70px;
  border-radius: 10px;
  background-color: #f3f3f3;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Message = styled.p`
  color: ${props => props.success ? "green" : "red"};
  font-size: 16px;
  font-weight: bold;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: #FF5C5C;  // Reddish color
    margin: 0;  // Prevents changing the size of the form
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();

  const { t } = useTranslation();

  const supportedLanguages = Object.keys(languageConfig).sort();

  const onSubmit = async (data) => {
    const { email, language } = data;

    setIsLoading(true); 

    try {

        //if(language=='') language = "English";
        const response = await axios.post(`${baseUrl}/register`, { email, language }, { withCredentials: true });
        
        setIsSuccess(response.data.success);
        setMessage(response.data.message);

    } catch (error) {
        //console.error(error);
        setMessage(t('RequestFailed'));
        setIsSuccess(false);
    }

    setShowMessage(true);
    setIsLoading(false); 
  };

  return (
  <SignupContainer>
    <TopBarNav />

    <FormContainer>
    <h1>{t('RegisterPageTitle')}</h1>
    {isLoading && 
      <SpinnerOverlay>
        <Spinner />
      </SpinnerOverlay>
    }

    {!isSuccess ? 
    (
    <>
    <IntroContainer>
        <p>{t('RegisterPageText1')}</p> 
        <p>{t('RegisterPageText2')}</p> 
        <p>{t('RegisterPageText3')}</p>
    </IntroContainer>
    <Form onSubmit={handleSubmit(onSubmit)}>
      
      <Input
        inputMode="email"
        maxLength={70}
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        type="email"
        placeholder={t('RegisterPageEmailText')}
      />
      {errors.email && <ErrorText>{t('SignupEmailValidate')}</ErrorText>}

      <Select {...register("language", { required: true, validate: value => value !== "default" })}>
        <option value="default">{t('LanguageSelectDefault')}</option>
        {supportedLanguages.map((language, index) => (
            <option key={index} value={language}>
            {t(language)}
            </option>
        ))}
      </Select>
      {errors.language && <ErrorText>{t('SignupLanguageValidate')}</ErrorText>}
      <Button type="submit">
      {t('HomepageRegisterButton')}
      </Button>
    </Form> </>) : null}

    {showMessage && 
      <MessageContainer>

        {isSuccess && 
        <>
        <Message success={true}>{t('RegisterSuccess1')}</Message>
        <Message success={true}>{t('RegisterSuccess2')}</Message>
        
        <Button onClick={() => navigate("/")}>{t('ReturnButton')}</Button>
        </>
        }

        {!isSuccess && 
        <>
        <Message success={false}>{message}</Message>
        </>
        }

      </MessageContainer>
    }

    </FormContainer>
  </SignupContainer>

  );
}

export default Register;
