import React from "react";
import { useChat } from '../context/ChatProvider';
import { useNavigate } from 'react-router-dom'; 

const useAuthRedirect = ({authCheck, restrictGuest = false}) => {
    const { user } = useChat();
    const navigate = useNavigate(); 

    React.useEffect(() => {
    
        if(authCheck){
            if (!user) {
                navigate('/');
            }

            if(user?.guest && restrictGuest){
                navigate('/');
            }
        }
        
    }, [user, navigate]); 
    
    return null;
};

export default useAuthRedirect;
