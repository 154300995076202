const scenarios = [
    "Elevator silence", "Ex-partner encounter", "Mismatched first date", "Tax meeting",
    "Dental chat", "Awkward family meet", "Job review", "Unfriendly pet situation",
    "Long flight neighbor", "Rarely seen neighbor", "Chatty hairdresser", "Talkative taxi ride",
    "Solo at a party", "Queue small talk", "Early to meeting", "Public sauna",
    "Landlord small talk", "Quiet waiting room", "Gym equipment share", "Traffic jam acquaintance",
    "Unfriended social meet", "Delayed meeting wait", "Bad blind date", "Neighbor disagreement",
    "Solo at couples event", "Borrowed item return", "Rain with stranger", "Distant relative meal",
    "Random project partner", "Different taste shopping", "Overheard conversation", "Coincidental walk",
    "Quiet office call", "Shared cafe table", "Coworker personal questions", "Outfit twin",
    "First time in-laws", "Critical relative talk", "Crying baby on transit", "Dismissive salesperson",
    "Late night bus stop", "New doctor appointment", "Advanced yoga class", "Service provider spat",
    "Post-argument friend", "Language barrier", "Wardrobe malfunction", "Cultural faux pas",
    "Bad video call", "Direction-refusing companion","Silent carpool", "Unexpected reunion", "Failed joke attempt", "Awkward silence at dinner",
    "Mismatched workout buddy", "Lost at a party", "Confusing date conversation", "Misunderstood text message",
    "Uncomfortable Uber pool", "Failed handshake", "Unwanted advice", "Misinterpreted comment",
    "Accidental insult", "Awkward hug", "Forgotten name", "Misheard question",
    "Mismatched movie tastes", "Uncomfortable truth or dare", "Invasive questionnaire", "Overly personal trainer",
    "Confusing cultural difference", "Botched surprise party", "Uncomfortable topic at dinner",
    "Odd encounter at gym", "Unwelcome party guest", "Misaligned political views", "Clashing music tastes",
    "Failed group photo", "Embarrassing slip of tongue", "Misread social cue", "Overly enthusiastic greeting",
    "Unexpected confrontation", "Awkward payment splitting", "Misplaced belongings", "Confusing directions",
    "Out-of-place at a concert", "Overly competitive game", "Uncomfortable beach day",
    "Unexpected guest", "Unplanned meeting", "Confusing small talk", "Awkward dance partner",
    "Mismatched travel companion", "Miscommunication at checkout", "Unwanted critique", "Overly familiar acquaintance",
    "Mistaken identity", "Awkward encounter with neighbor", "Uncomfortable elevator talk", "Misunderstood sarcasm",
    "Awkward gift exchange", "Misjudged personal space"
];

function getRandomInteraction() {
    const randomIndex = Math.floor(Math.random() * scenarios.length);
    return scenarios[randomIndex];
}

module.exports = {
    getRandomInteraction
};
