import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { MdHomeFilled } from "react-icons/md"

const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black background
  display: flex;
  justify-content: flex-end; // Place the links to the right
  z-index: 1000; // Ensure it's on top
`;

const TopLink = styled(Link)`
  color: white;
  margin-left: 1rem;
  text-decoration: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
  }

  & img {
    width:25px;
    height:22px;
    padding-left:3px;
    margin-top:3px;
  }
  
`;

const TopLinkLine = styled.a`
  color: white;
  margin-left: 1rem;
  text-decoration: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
  }

  & img {
    width:25px;
    height:22px;
    padding-left:3px;
    margin-top:3px;
  }
  
`;


const HomeButton = styled(Link)`
    display: flex;
    align-items: center;
    color: white;
    margin-right: auto; // pushes it to the left-most side
    text-decoration: none;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.85;
    }
  
  
`;

const TopBarNav = ({onSignInClick}) => {

    const { t } = useTranslation();

    return (
        <TopBar>
            <HomeButton to="/">
                <MdHomeFilled size={24} />
            </HomeButton>
            <TopLink to="/about-us">{t('TopBarAboutUs')}</TopLink> 
            <TopLink to="/privacy">{t('Privacy')}</TopLink> 
            <TopLink onClick={onSignInClick}>{t('AccountSignUp')}</TopLink>
            <TopLinkLine href="https://line.me/R/ti/p/@014wkcef" target="_blank"><img src={"./line-icon.png"} alt="LINE Add friend" ></img></TopLinkLine>
        </TopBar>
    );

};

export default TopBarNav;