import React from 'react';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #404258;  // Feel free to adjust the color or use a gradient.
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;  // Ensuring it's on top
`;

const LoadingScreen = () => (
  <LoadingContainer>
    <BeatLoader color="white" />
  </LoadingContainer>
);

export default LoadingScreen;
