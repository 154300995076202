import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import { ErrorMessage } from '../styled/ErrorMessage';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import axios from 'axios';
import { BsFillClipboard2PlusFill, BsFillEnvelopeAtFill } from 'react-icons/bs';  
import { ClipLoader } from 'react-spinners';
import { fetchLanguages } from "../context/LanguageUtility";
import {languageConfig} from "../context/LanguageConfig";

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const ModalContentTemp = styled.div`
    background: #fff;
    padding: 2em;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    position: relative;
    color: #000;
    margin:10px;
    text-align:center;
  
    p {
      padding:0.5rem;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.7em;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
    margin-bottom:10px;
    margin-top:10px;
  -webkit-appearance: none;

    @media (max-width: 768px) {
      padding: 1.1em; // Increase padding for mobile
    }
`;

const QRCodeWrapper = styled.div`
  max-width: 74%;  // Restricting max width
  max-height: 300px;  // Setting max height
  margin: 10px 0;  // Some spacing around QR code
`;

const ShareButton = styled.button`
  padding: 10px 15px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  width:11em;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;  // Giving some spacing between the icon and the text
`;

const ButtonContainer = styled.div`
    display: flex;              
    gap: 10px;                  
    justify-content: center;    // Center the buttons horizontally
`;

const ShareButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;    // Center the buttons horizontally
    margin-left: 3em;
  
  @media (max-width: 768px) {
    margin-left: 0; 
  }
`;

const ShareActionsContainer = styled.div`
    display: flex;              
    flex-direction: column;
    //gap: 10px;                  
    justify-content: center;    // Center the buttons horizontally
    margin-left: 5em;
`;

const ChatInstructionsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ChatInstructions = styled.p`
  text-align: left;
  //max-width: 80%;  // Adjust this value as needed for your design
`;

const StyledLabel = styled.label`
    font-size: 1em;
    margin-bottom: 0.5em;
    //display: flex;
    align-items: center;
    gap: 1em;
`;

const StyledInput = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
    margin-bottom:10px;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const StyledButton = styled.button`
  max-width: 150px;           // Limit the button width
  padding: 10px 20px;         // Add some horizontal padding
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const LineInviteCode = styled.div`
`

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
  border: 4px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
`;

const ShareModal = ({ inviteLink, inviteCode, onClose, onLanguageSelected }) => {
  const { t } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState(null);
  const [customLanguage, setCustomLanguage] = useState(null);
  const [inviteLinkWithLang, setInviteLinkWithLang] = useState(null);
  const [chatInstructions, setChatInstructions] = useState(null);
  const [showInstructionView, setShowInstructionView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [languageConfiguration, setLanguageConfiguration] = useState(null);
  const [lineInviteCode, setLineInviteCode] = useState(null);
  
  //const supportedLanguages = Object.keys(languageConfig).sort();
  useEffect(() => {
    fetchLanguages(setSupportedLanguages, setLanguageConfiguration);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLinkWithLang).then(() => {
      alert(t('ChatShareModalCopyAlert'));
    });
  };

  const handleEmailShare = () => {
    
    let culture;
    if(languageConfiguration[languageSelected]?.loc) {
      culture = languageConfiguration[languageSelected].loc;
    }else{
      culture = 'en'; // default to english if language selected loc not found
    }
    
    const translate = i18n.getFixedT(culture);

    const subject = encodeURIComponent(translate('ChatShareModalEmailSubject'));
    const body = encodeURIComponent(`${translate('ChatShareModalInstruction')}  - ${inviteLinkWithLang}`);
    window.open(`mailto:?subject=${subject}&body=${body}`);
  };
  
  const setupInstructionsView = async (chosenLanguage) => {

    //console.log(`Setting up instruction view for ${chosenLanguage}`);
    
    // get translated share message
    //const selectedLang = languageConfig[chosenLanguage].loc;  // refactor to pull from supported languages ..
    //const selectedCulture = supportedLanguages[chosenLanguage].loc;
    // const selectedCulture = languageConfiguration[chosenLanguage].loc;
    //
    // await i18n.loadLanguages(selectedCulture);
    //
    // // Load namespaces (translations) for the selected language
    // i18n.loadNamespaces('translation', () => {
    //   setLanguageSelected(chosenLanguage);
    // });

    // get the instruction translation for selected language
    //let instructions = i18n.getFixedT(selectedCulture)('ChatShareModalInstruction');  // refactor this to come from supported languages
    
    let selectedCulture = languageConfiguration[chosenLanguage].loc;
    let instructions = languageConfiguration[chosenLanguage].instructions;
    
    setChatInstructions(instructions);
    
    // update the invite link to include the language selected
    setInviteLinkWithLang(`${inviteLink}?lang=${selectedCulture}&full=${chosenLanguage}`);
  };
  
  const checkCustomLanguage = async (language) => {

    // 1. call webserver endpoint to do validation 
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/lang/c`, {customLanguage:language}, { withCredentials: true });

    // if not valid, update error message appropriately
    if(!response.data.success){
      if(response.data.suggestion) {
        setErrorMessage(`Did you mean '${response.data.suggestion}'?`);
      }else{
        setErrorMessage("Sorry we don't recognize that as a language.");
      }
    }

    return response.data;
  }
  
  const handleContinue = async () =>{
    
    setErrorMessage("");
    
    // validation on required
    if(!languageSelected || languageSelected === 'default') {
      setErrorMessage("Please choose a language");
      return;
    }
    
    setIsSubmitting(true);
    
    // check if a custom language was entered 
    if(languageSelected === 'Other'){
      
      if(!customLanguage){
        setErrorMessage("Please specify a language");
      }else{
        
        // check if the custom language entered is actually in our supported language list 
        // ... in case user didnt notice, we can save a network request here ..
        if(supportedLanguages[customLanguage]){
          // already have this language in supported languages, so grab from there ..
          setLanguageSelected(customLanguage);
          
          let culture = languageConfiguration[customLanguage].loc; //supportedLanguages[customLanguage].loc;
          //await i18n.loadLanguages(culture);
          //i18n.loadNamespaces('translation', () => {});
          //setChatInstructions(i18n.getFixedT(culture)('ChatShareModalInstruction'));
          
          setChatInstructions(languageConfiguration[customLanguage].instructions);
          setInviteLinkWithLang(`${inviteLink}?lang=${culture}&full=${customLanguage}`);
          setShowInstructionView(true);
        }else{
          const isCustomLanguageValid = await checkCustomLanguage(customLanguage);
          if(isCustomLanguageValid.success){
            // configure other language entered (db)

            // translated instructions should now come back from call to isCustomLanguage
            
            // grab english version (default) of share instructions and translate into 'other' language

            let defaultCulture = isCustomLanguageValid.culture;
            let instructions;
            
            if(isCustomLanguageValid.settings){
              //console.log('custom language instructions found ..', customLanguage);
              instructions = isCustomLanguageValid.settings.instructions;
            }else{
              //console.log('custom language instructions NOT FOUND ... defaulting to english ..', customLanguage);
              //await i18n.loadLanguages(defaultCulture);
              //i18n.loadNamespaces('translation', () => {});
              //instructions = i18n.getFixedT(defaultCulture)('ChatShareModalInstruction');
              instructions = languageConfiguration['English'].instructions;
            }
            
            setChatInstructions(instructions);

            // update the invite link to include the language selected
            setInviteLinkWithLang(`${inviteLink}?lang=${defaultCulture}&full=${customLanguage}`);

            setShowInstructionView(true);
          }
        }
        
      }
      
    }else{
      // selected a supported language 
      
      // add selected language to room (so it translates to that langauage as well)
      onLanguageSelected(languageSelected);
      
      // get translated share message
      await setupInstructionsView(languageSelected)
      
      // show translated message view
      setShowInstructionView(true);
    }
    
    setIsSubmitting(false);
  };

  const handleLanguageChange = (e) => {
    setLanguageSelected(e.target.value);
    setErrorMessage("");
  }
  
  return (
        
    <ModalBackground onClick={onClose}>
      <ModalContentTemp onClick={(e) => e.stopPropagation()}>
        {isSubmitting && <SpinnerOverlay><ClipLoader size='25px' color='#3c95f4' /></SpinnerOverlay>}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage> }
        
        {showInstructionView ? (
          <>
            <p>[{t('ChatShareModalInstruction')}]</p> 
            {/*<p>{i18n.getFixedT(languageConfig[languageSelected].loc)('ChatShareModalInstruction')}</p>*/}
            <ChatInstructionsContainer>
              <ChatInstructions>{ chatInstructions }</ChatInstructions>  
            </ChatInstructionsContainer>
            
            <ShareActionsContainer>
              <QRCodeWrapper>
                {
                  inviteLinkWithLang && <QRCode value={inviteLinkWithLang} size={150} />
                }
              </QRCodeWrapper>
              <ShareButtonContainer>
                <ShareButton onClick={handleCopy}>
                  <IconWrapper><BsFillClipboard2PlusFill size={24} /></IconWrapper>
                  {t('ChatShareModalCopy')}
                </ShareButton>
                <ShareButton onClick={handleEmailShare}>
                  <IconWrapper><BsFillEnvelopeAtFill size={24} /></IconWrapper>
                  {t('ChatShareModalEmail')}
                </ShareButton>
                {/*<LineInviteCode>*/}
                {/*  [ LINE Invite Code : { inviteCode } ]*/}
                {/*</LineInviteCode>*/}
              </ShareButtonContainer>
              
            </ShareActionsContainer>
          </>
        ) : (
          <>
          <StyledLabel>
            <p>{t('ChatShareModalLangSelect')}</p>
            <Select onChange={e => handleLanguageChange(e)}>
              <option value="default">{t('LanguageSelectDefault')}</option>
              {supportedLanguages.map((language, index) => (
                  <option key={index} value={language}>
                    {t(language)}
                  </option>
              ))}
              <option value="Other">Other</option>
            </Select>
          </StyledLabel>
            
          {languageSelected === 'Other' && (
            <StyledLabel>
              <StyledInput
                  type="text"
                  placeholder="Please specify the language"
                  value={customLanguage}
                  onChange={e => setCustomLanguage(e.target.value)}
                  required={languageSelected === 'Other'} // Only required when 'Other' is selected
              />
            </StyledLabel>
          )}

          <ButtonContainer>
            <StyledButton onClick={handleContinue}>{t('ContinueButton')}</StyledButton>
          </ButtonContainer>
          </>
        )}
      </ModalContentTemp>
    </ModalBackground>

  );
};

export default ShareModal;
