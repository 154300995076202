import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import Login from "./Login";

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const ModalContentTemp = styled.div`
    background: #fff;
    padding: 1.2em;
    border-radius: 10px;
    //max-width: 500px;
    //width: 90%;
    position: relative;
    color: #000;
    margin:10px;
    text-align:center;

  // width:330px;
  
    //p {
    //  padding:0.5rem;
    //}
`;

const Header = styled.div`
    font-weight:bold;
`;

const Message = styled.div`
    font-size:1em;
    color:red;
    width:80%;
    margin-left:1em;
`;

const LoginModal = ({message, onClose }) => {
    const {t} = useTranslation();

    return (
        <ModalBackground onClick={onClose}>
            <ModalContentTemp onClick={(e) => e.stopPropagation()}>
                <Login initialMessage={message} />
            </ModalContentTemp>
        </ModalBackground>
    );
};

export default LoginModal;