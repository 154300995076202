import React from 'react';
import styled from 'styled-components';
//import Navigation from './Navigation';
import useAuthRedirect from '../hooks/useAuthRedirect';
import { useChat } from '../context/ChatProvider';

const WrapperContainer = styled.div`
  display: grid;
  height: 100vh;
  place-items: center;
  overflow-x: hidden;
  overflow-y: ${props => props.enableScrolling ? 'auto' : 'hidden'}; // Controlling vertical scroll
`;

const ChatAppContainer = styled.div`

  // Setting the padding around the UI
    --vertical-padding: 2.1vh;

    display: flex;
    //gap: 2vw;
    height: 80vh;
    width: 80vw;
    justify-content: space-between;
    //background: white; //#e5e7e8;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    //            rgba(0, 0, 0, 0.12) 0px -12px 30px,
    //            rgba(0, 0, 0, 0.12) 0px 4px 6px,
    //            rgba(0, 0, 0, 0.17) 0px 12px 13px,
    //            rgba(0, 0, 0, 0.09) 0px -3px 5px;

    @media (max-width: 820px) {
        position: relative;
        width: 100%;
        height: 100vh;
        flex-direction: column-reverse;
        font-size: 0.85rem;
        gap: 0;
    }
`;

const Wrapper = ({ children, authCheck = true, restrictGuest = false, enableScrolling = true }) => {

    useAuthRedirect({authCheck: authCheck, restrictGuest: restrictGuest});

    const { isTextareaFocused, setIsNavOpen, isNavOpen } = useChat(); 
    
    return (
        <WrapperContainer enableScrolling={enableScrolling}>
            <ChatAppContainer>
                {/*{(!isTextareaFocused) && (*/}
                {/*    <Navigation openRoomNav={() => { */}
                {/*        setIsNavOpen(!isNavOpen);*/}
                {/*    }} />*/}
                {/*)}*/}

                { children }

            </ChatAppContainer>
        </WrapperContainer>
    );
};

export default Wrapper;