import React, { useState, useEffect } from 'react';
import { MdShare, MdOutlinePersonAddAlt, MdPersonAdd } from 'react-icons/md';
import styled from 'styled-components';
import RoomList from './RoomList';
import ChatForm from './ChatForm';
import Conversation from './Conversation';
import MessageSending from './MessageSending';
import { useChat } from '../context/ChatProvider';
import Wrapper from './Wrapper';
import axios from 'axios';
import ShareModal from './ShareModal';
import ModalAlert from './ModalAlert';
import OnlineUsersModal from './OnlineUsersModal';
import useChatActions from '../hooks/useChatActions';
import RoomInviteModal from '../hooks/useRoomInvites';
import { useTranslation } from "react-i18next";
import SomeoneTyping from "./SomeoneTyping";
import SubscribeModal from "./SubscribeModal";
import RoomForm from "./RoomForm";
import Navigation from './Navigation';
import {ButtonContainer} from "../styled/Button";
import { Link } from 'react-router-dom';
import { AiOutlineBars } from "react-icons/ai"
import LoginModal from "./LoginModal";

const CenterContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 1.5vw;
    flex-direction: column;
    height: 100%;
    margin: auto 0;
    //padding: 3vw 1vw;
    justify-content: space-between; 

    @media (max-width: 820px) {
        height: 80%;
    }
`;

const Chat = styled.div`
    //padding: var(--vertical-padding) var(--vertical-padding) 1.5vh var(--vertical-padding);
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 80%;
    width: 100%;
    background: #fff;
    //border-radius: 30px;
    position: relative;
  background: var(--blue-gradient);

    // @media (max-width: 820px) {
    //     margin: 0 2.5vw;
    // }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    border-bottom: 1px solid rgb(123, 123, 123);
    padding-bottom: 0.3em;
    height: 4em;
  margin-left:0.3em;
  margin-right:0.3em;
  overflow:hidden;
  margin-top:0.3em;
  background: var(--blue-gradient);
    
    //& img {
    //    height: 3.5em;
    //    border-radius: 0.7em;
    //}

    & h2 {
        font-size: 0.85em;
        font-weight: 600;
      color:white;
      max-width:15em;
    }
`;

const WelcomeMessage = styled.p`
    margin: auto 0;
    font-size: 0.9em;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
`;

const ShareIcon = styled(MdPersonAdd)`
    cursor: pointer;
    font-size: 1em;
    padding:4px;
    color: var(--blue-gradient);
      //background-color: #8CB9BD;
      background-color:white;
      width:3em;
      height:3em;
      border-radius:50%;
    //margin-left: auto;  // push it to the rightmost side
  margin-top:2.5em;
  
`;

const InviteIcon = styled(MdShare)`
    cursor: pointer;
    font-size: 2em;
    //margin-left: auto;  // push it to the rightmost side
`;

const RoomImagesContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left:auto;
    // Add more styling as needed
`;

const RoomImage = styled.img`
    //height: 3.5em;
    //border-radius: 0.7em;
    //position: absolute;
    left: ${props => props.offset}px; // dynamically set left offset
    //margin-right: 10px;
    //padding-bottom:5px;
    //padding: 0.5em;
    width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
    //margin-left: ${props => props.offset}px;
    margin-left:-30px;
  margin-top:1.3em;
  border: 1.5px solid white; // Add a white border
  box-shadow: 0 0 0 3px white;
`;

const StyledButton = styled.button`
  width: 70%;
  margin-top: 10px;
  margin-left:10px;
  padding: 25px 25px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const StartConvoButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ChatContainer = () => {
    const { socket, initJoin, setInitJoin, currentRoom, isMessageSending, setIsMessageSending, isTyping, whoIsTyping, user, isNavOpen, setIsNavOpen, setActiveView, onlineUsers = [], connectionState, getRoomUsers, isMobile, blockSending, setBlockSending, setRooms, rooms, joinRoom, setLoadingMessages, setCurrentRoom, addLanguageToRoom } = useChat();
    const { inviteToRoom } = useChatActions();
    const [showModal, setShowModal] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [isOnlineUsersModalOpen, setIsOnlineUsersModalOpen] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loginModalMessage, setLoginModalMessage] = useState('');
    
    const { t } = useTranslation();

    const isGuest = user?.guest;
    
    const GUEST_MESSAGE_QUOTA = 5;
    
    const generateInvite = async () => {
        try{
            if(isGuest){
                // show login modal
                showLogin("To invite others, you need an account. Create one or sign in below.");
                return;
            }
            
            if(blockSending) return;
            
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/gen-invite/${currentRoom.id}`, { withCredentials: true });
            setInviteLink(response.data.link);  
            setInviteCode(response.data.lineInviteCode);
            setShowModal(true); 
        }catch(err){
            console.log(err);
        }
    }
    
    const updateRoomLanguages = async (language) => {
        try{
            // update on client
            addLanguageToRoom(currentRoom.id, language);
            
            // update on db side
            
        }catch(e){
            console.log(e);
        }
    }
    
    const showLogin = (message) => {
        setLoginModalMessage(message);
        setShowLoginModal(true);
    }

    const handleShowRoomListClick = () => {
        if(!isNavOpen){
            setIsNavOpen(true);
            if(isMobile){
                setActiveView("chat");
            }else{
                setActiveView("roomNav");
            }
        }else{
            setIsNavOpen(false);
            setActiveView("chat");
        }

        window.scrollTo(0, 0);
    };

    const toggleOnlineUsersModal = () => {
        setIsOnlineUsersModalOpen(!isOnlineUsersModalOpen);
    }

    const handleInviteToRoom = async (invitedUserId) => {
        inviteToRoom(invitedUserId, user.id, user.username, currentRoom.id);
    };

    // handles showing of disconnect modal alert if connection to server is lost
    useEffect(() => {
        // Check if the user is on the /chat route and the socket is disconnected
        if (connectionState === 'disconnected') {
            setShowDisconnectModal(true);
        } else {
            setShowDisconnectModal(false);
            
            if(currentRoom){
                console.log("CHAT joinRoom on socket connect ...", currentRoom.id);
                console.log(currentRoom);
                // check to join room?
                joinRoom(currentRoom.id);
            }
        }
    }, [connectionState]);

    
    // only do this for mobile
    useEffect(() => {
        //if(!isMobile) return;
        
        window.scrollTo(0, 0);
        // Add code to disable scrolling
        document.body.style.overflowY = 'hidden';

        return () => {
            // Re-enable scrolling when the component unmounts
            document.body.style.overflowY = 'auto';
        };
    }, []);
    
    // automatically load room if one is set
    useEffect(()=>{

        if(currentRoom){
            joinRoom(currentRoom.id);
            setActiveView("chat");
            //setIsNavOpen(false);
            window.scrollTo(0, 0);
        }

        return () => {};
    },[currentRoom]);
    
    const showQuotaReachedModal = async (message) => {
        
        if(!message.user) return;

        // check to ensure we block the right user
        if(message.user.id === user.id){
            
            if(user.guest){
                // show message specific to getting them to sign up/in
                showLogin("To continue, please create an account or sign in below.");
            }else{
                setIsSubscribeModalOpen(true);
            }

            setBlockSending(true);
            setIsMessageSending(false);
        }
    };
    
    useEffect(() => {
        if(socket){
            socket.on('quota-reached', showQuotaReachedModal);
            
            // if(initJoin){
            //     joinRoom(initJoin);
            //     setInitJoin(null);
            // }
            
            return () => {
                socket.off('quota-reached');
            }
        }
    },[socket])
    
    // on load of component, check if able to send messages (i.e. free trial ended)
    useEffect(() => {
        
        if(!user.subscriber){
            //console.log(user);
            if((user.messagesSent >= user.messageQuota)){
                setBlockSending(true);
                // show subscribe modal if opening an existing conversation and out of free trial
                setIsSubscribeModalOpen(true);
            }
        }
        
        if(user.guest){
            if(user.messagesSent >= GUEST_MESSAGE_QUOTA){
                setBlockSending(true);
                showLogin("To continue, please create an account or sign in below.");
            }
        }
        
    },[]);

    const toggleCreateRoomModal = () => {

        if(isGuest){
            // show login modal
            setShowLoginModal(true);
            return;
        }
        
        // disable if free trial is over
        if(blockSending) return;
        
        setIsModalOpen(!isModalOpen);
    };

    // Callback from RoomForm (create new babble)
    const addNewRoom = (newRoom) => {
        setRooms([...rooms, newRoom]); // Add the new room to the beginning of the rooms list

        if(isGuest) return;

        // if a new conversation is created, automatically join/start

        setLoadingMessages(true);

        //const selectedRoom = rooms.find(room => room.id === roomID);

        setCurrentRoom(newRoom);
        // setting this so it can be checked on reconnect to auto join room user was in when disconnected ..
        //localStorage.setItem('currentRoom', selectedRoom.id);
        sessionStorage.setItem('currentRoomId', newRoom.id);
        sessionStorage.setItem('currentRoom', JSON.stringify(newRoom));

        const roomLanguages = newRoom.languages;
        socket.emit('join-room', { roomID:newRoom.id, roomLanguages:roomLanguages, userJoining:user } );

        setActiveView("chat");
        setIsNavOpen(false);

        window.scrollTo(0, 0);
    }

    const handleConversationListClick = () => {
        setIsNavOpen(true);
        setActiveView("roomNav");

        window.scrollTo(0, 0);
    }
    
    // const onShowLoginModal = () => {
    //     setShowLoginModal(true);
    // }
    
    return (
        <Wrapper enableScrolling={false}>
            <CenterContainer>
                
                <RoomForm isOpen={isModalOpen} onNewRoom={addNewRoom} onClose={toggleCreateRoomModal} />

                {showLoginModal && <LoginModal onClose={() => setShowLoginModal(false)} message={loginModalMessage} />}
                
                <Chat>
                    {
                        // TODO: if there is no current room, then create one and join it ..
                        !currentRoom ? 
                        <>
                            <Header>
                                    <ButtonContainer active={false} onClick={handleConversationListClick}>
                                        <Link to="/chat">
                                            <AiOutlineBars size='100%' />
                                        </Link>
                                    </ButtonContainer>
                            </Header>
                        </>
                        :
                        <>
                            <Header>
                                    <ButtonContainer active={false} onClick={handleConversationListClick}>
                                        <Link to="/chat">
                                            <AiOutlineBars size='100%' />
                                        </Link>
                                    </ButtonContainer>
                                <div>
                                    <h2>{ currentRoom.name }</h2>
                                    {/* <Description color='#000' size='0.75em'>{ currentRoom.description }</Description> */}
                                </div>
                                
                                <RoomImagesContainer>
                                    {
                                    Array.from(new Set(getRoomUsers(currentRoom.id).map(user => user.avatar)))
                                        .filter(avatar => avatar)
                                        .map((avatar, index) => (
                                            <RoomImage
                                                key={avatar}
                                                src={avatar}
                                                alt={`User Avatar ${index}`}
                                                offset={index * -45}
                                            />
                                        ))
                                    }

                                    { (currentRoom.ownerId === user.id) &&
                                        <>
                                            <ShareIcon onClick={ generateInvite } />
                                        </>
                                    }
                                </RoomImagesContainer>
                                
                                
                            </Header>
                            
                            <Conversation />

                            {/*{ isMessageSending && <MessageSending /> }*/}

                            {/*{ isTyping && <SomeoneTyping message={whoIsTyping} /> }*/}
                            
                            <ChatForm />
                        </>
                    }
                </Chat>

                {showModal && 
                    <ShareModal 
                        inviteLink={inviteLink} 
                        inviteCode={inviteCode} 
                        onClose={() => setShowModal(false)} 
                        onLanguageSelected={updateRoomLanguages}
                />}

                {Array.isArray(onlineUsers) && user && <OnlineUsersModal currentUserId={user.id} onlineUsers={onlineUsers} onInvite={handleInviteToRoom} isOpen={isOnlineUsersModalOpen} onClose={toggleOnlineUsersModal} />}

                <RoomInviteModal />

                <ModalAlert isOpen={showDisconnectModal} isSuccess={false} title={t('DisconnectedModalTitle')} message={t('DisconnectModalText')} showCloseButton={false} />

                {isSubscribeModalOpen && <SubscribeModal onClose={() => setIsSubscribeModalOpen(false)} userId={user.id} userEmail={user.email} title={t('FTOverModalTitle')} message={t('FTOverModalDesc')}></SubscribeModal>}
                
            </CenterContainer>

            {isNavOpen && <RoomList showLoginModal={showLogin} />}

        </Wrapper>
    );
};

export default ChatContainer;