import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useChat } from '../context/ChatProvider';
import RoomForm from './RoomForm'; 
import ConfirmationModal from '../styled/ConfirmationModal';
import axios from 'axios';
import RoomItem from './RoomItem';
import { useTranslation } from "react-i18next";
import {ButtonContainer} from "../styled/Button";
import { GiExitDoor } from "react-icons/gi"
import { AiFillSetting, AiOutlineBars } from "react-icons/ai"
import { googleLogout } from '@react-oauth/google'
import useChatActions from "../hooks/useChatActions";


const RoomListContainer = styled.div`
    --space: 0.8em;
    --horizontal-space: 1.8vw;
    
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    padding-top: var(--vertical-padding);
    overflow: auto;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    background: var( --blue-gradient);
    color: #fff;

    //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px;
    z-index:2;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px,
      rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
  
  & h3 {
        font-size: 1.2em;
        font-weight: 500;
        padding: 0.9em var(--horizontal-space);
    }

    @media (max-width: 820px) {
        position: absolute;
        opacity: ${ props => props.open ? '1' : '0'};
        pointer-events: ${ props => props.open ? 'null' : 'none'};
        right: 0;
        width: 100%;
        border-radius: 0;
        z-index: 1;
        background: var( --blue-gradient);
    }
`;

const StartConvoButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height:3.5em;
`;

const StyledButton = styled.button`
  //width: 90%;
  //margin-top: 10px;
  padding: 10px 30px 10px 30px;
  border-radius: 15px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;  // Giving some spacing between the icon and the text
  margin-top:10px;
`;



const ConversationSectionHeader = styled.div`
  margin-top:45px;
  margin-bottom:15px;

  @media (max-width: 820px) {
    margin-top:0;
  }
`;

const Header = styled.div`
  //font-size: 0.8em;
  //padding: 10px 10px; 
  //margin-bottom: 10px; 
  //margin-top:20px;
  border-bottom: 1px solid rgb(123, 123, 123);
  //margin-left:15px;
  //margin-right:15px;
  margin-top:15px;
  margin-bottom:15px;

  @media (max-width: 820px) {
    margin-top:0;
  }
`;

const ConversationSection = styled.div`
  background: var( --blue-gradient);
  height:100%;

  @media (max-width: 820px) {
    padding:10px;
  }
  
`;

const HeaderContainer = styled.div`
    //padding: var(--vertical-padding) var(--vertical-padding) 1.5vh var(--vertical-padding);
    display: flex;
    //flex: 1;
    //flex-direction: column;
    height: 2.3em;
    width: 100%;
    //background: #fff;
    //border-radius: 30px;
    position: relative;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.08);

     @media (max-width: 820px) {
       //background: #fff;
     }
`;

const LeftSideButton = styled.div`
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    gap:0.2em;
    width:2em;
  margin-top:1.5em;
  margin-left:0.3em;
  
  

  @media (max-width: 820px) {
    //width:16em;
  }
  
`;

const RightSideButton = styled.div`
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  gap:0.2em;
  margin-left:10px;

  margin-top:1.5em;
  margin-right:0.3em;
`;

const RoomListHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.5em;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 0.5em;
    //height: 0.8em;
    width:100%;
    
    //& img {
    //    height: 3.5em;
    //    border-radius: 0.7em;
    //}

    & h2 {
        font-size: 0.85em;
        font-weight: 600;
    }
`;

const RoomList = ({showLoginModal}) => {
    const { socket, joinRoom, currentRoom, setCurrentRoom, rooms, setRooms, isNavOpen, user, blockSending, setLoadingMessages, setActiveView, activeView, setIsNavOpen, isMobile } = useChat();
    const { logout } = useChatActions();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [roomIdToDelete, setRoomIdToDelete] = useState(null);
    const [roomNameToDelete, setRoomNameToDelete] = useState(null);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    
    const { t } = useTranslation();

    const isGuest = user?.guest;
    const filteredRooms = rooms || [];
    
    const handleRoomClick = (roomID) => {

        //if(isGuest) return;
        
        joinRoom(roomID);
    }

    const handleDeleteRoom = async (roomId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/rooms/${roomId}`, { withCredentials: true });
    
            if (response.data.success) {
                setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId)); 
            } else {
                console.error("Error deleting room:", response.data.error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };
    
    const addNewRoom = (newRoom) => {
        
        setRooms([...rooms, newRoom]); // Add the new room to the beginning of the rooms list
        
        // if a new conversation is created, automatically join/start
        
        setLoadingMessages(true);

        //const selectedRoom = rooms.find(room => room.id === roomID);

        setCurrentRoom(newRoom);
        // setting this so it can be checked on reconnect to auto join room user was in when disconnected ..
        //localStorage.setItem('currentRoom', selectedRoom.id);
        sessionStorage.setItem('currentRoomId', newRoom.id);
        sessionStorage.setItem('currentRoom', JSON.stringify(newRoom));

        const roomLanguages = newRoom.languages;
        socket.emit('join-room', { roomID:newRoom.id, roomLanguages, userJoining:user } );

        setActiveView("chat");
        setIsNavOpen(false);

        window.scrollTo(0, 0);
    }

    const updateRoomImage = (roomId, newImage) => {
        setRooms(prevRooms => {
            return prevRooms.map(room => {
                if (room.id === roomId) {
                    return { ...room, image: newImage };
                } else {
                    return room;
                }
            });
        });
    };

    const toggleCreateRoomModal = () => {
        
        if(isGuest){
            // show login modal
            showLoginModal("To create new babbles, you need an account. Create one or sign in below.");
            return;
        }
        
        // disable if free trial is over
        if(blockSending) return;
        
        setIsModalOpen(!isModalOpen);
    };

    const handleAccountClick = () => {
        
        if(currentRoom){
            //setCurrentRoom(null);
            //leaveRoom(currentRoom.id);
        }

        setActiveView("account");

        window.scrollTo(0, 0);
    }

    const handleLogoutConfirmation = () => {
        setShowLogoutModal(false);

        let clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

        // Call this function to initiate Google logout
        const gLogout = () => {
            //console.log('Google sign out.');
            googleLogout();
            logout();
        };

        const emailLogout = () => {
            logout();
        };

        let userLoggedInWithGoogle = sessionStorage.getItem("userLoggedInWithGoogle");
        // Determine logout method based on how the user logged in
        if (userLoggedInWithGoogle === "TRUE") {
            gLogout();
        } else {
            emailLogout();
        }
    }

    const handleConversationListClick = () => {
        setIsNavOpen(false);
        setActiveView("chat");

        window.scrollTo(0, 0);
    }
    
    return (
        <RoomListContainer open={ isNavOpen }>

            {/*<ConnectionStatus /> */}
            {/* <OnlineUsersCount /> */}
            
            <HeaderContainer>
                <RoomListHeader>

                    <LeftSideButton>

                        {isMobile && (
                            <ButtonContainer active={true} onClick={handleConversationListClick}>
                                <Link to="/chat">
                                    <AiOutlineBars size='100%' />
                                </Link>
                            </ButtonContainer>
                        )}
                        
                    </LeftSideButton>
                    
                    <RightSideButton>
                            <>
                                <StartConvoButtonContainer>
                                    <StyledButton onClick={toggleCreateRoomModal}>
                                        {t('RoomListCreate')}
                                    </StyledButton>
                                </StartConvoButtonContainer>
                            </>

                        { !isGuest &&
                            <>
                            <ButtonContainer isAvatar active={false} onClick={handleAccountClick}>
                                <Link to="/account">
                                    <AiFillSetting size='100%' />
                                </Link>
                            </ButtonContainer>
                        
                            <ButtonContainer active={false} onClick={(e) => {
                                e.preventDefault();
                                setShowLogoutModal(true);
                            }}>
                                <a href="#">
                                    <GiExitDoor size='70%' />
                                </a>
                            </ButtonContainer>
                            </>
                        }
                    </RightSideButton>
                    
                </RoomListHeader>
            </HeaderContainer>
            
            {showLogoutModal && <ConfirmationModal
                onClose={() => setShowLogoutModal(false)}
                onConfirm={handleLogoutConfirmation}
                message={t('LogoutModalText')}
            />}

            <RoomForm isOpen={isModalOpen} onNewRoom={addNewRoom} onClose={toggleCreateRoomModal} />

            <ConversationSection>
                <ConversationSectionHeader></ConversationSectionHeader>
                <ul>
                    {
                        filteredRooms.map(room => {
                            const { id, isPublic, ownerId } = room;
                            const showDeleteIcon = !isPublic && ownerId === user.id && !isGuest;

                            return (
                                <RoomItem
                                    key={id}
                                    room={room}
                                    isActive={currentRoom?.id === id}
                                    handleClick={handleRoomClick}
                                    showDeleteIcon={showDeleteIcon}
                                    handleDelete={(id, name) => { setRoomIdToDelete(id); setRoomNameToDelete(name); }}
                                    unreadMessagesCount={room.unreadMessagesCount}
                                    updateRoomImage={updateRoomImage}
                                />
                            );
                        })

                    }
                </ul>
            </ConversationSection>
            
            {
                roomIdToDelete && <ConfirmationModal 
                    onClose={() => setRoomIdToDelete(null)}
                    onConfirm={() => {
                        handleDeleteRoom(roomIdToDelete);
                        setRoomIdToDelete(null);
                    }}
                    message={`${t('RoomListDeleteConfirm', {roomNameToDelete})}`}
                />
            }

        </RoomListContainer>
    );
};

export default RoomList;