import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getFirstLetter } from '../helpers';
import useMessages from '../hooks/useMessages';
import { useChat } from '../context/ChatProvider';
import MessageContainer from './MessageContainer'
import { MessageContent } from './MessageContent';
import { languageConfig } from '../context/LanguageConfig';
import { BeatLoader } from "react-spinners";

const ConversationContainer = styled.div`
    display: flex;
    //flex-direction: column;
    flex-direction: column-reverse;
    gap: 1vh;
    flex: 1;
    padding: 20px 0;
    overflow: auto;
    overflow-y: auto;  // to ensure scrolling capability
`;

const UserProfile = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    align-items: end;
    padding-bottom:5px;

    &::before {
        content: '${props => props.content && !props.isImage ? getFirstLetter(props.content) : ""}';
        display: grid;
        place-content: center;
        padding: 0.5em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 50%;
        //background: ${props => props.isImage ? `url(${props.content}) center/cover no-repeat` : 'var(--secondry-color-dark-palette)'};
      background: ${props => props.isImage ? `url(${props.content}) center/cover no-repeat` : 'var(--secondry-color-dark-palette)'};
      border: ${props => props.isImage ? '1.5px solid white' : 'none'};
    }
`;

const BotMessage = styled.div`
    width: fit-content;
    margin: 0 auto;
    padding: 0.85em 1.7em;
    font-size: 0.7em;
    text-align: center;
    border-radius: 2em;
    background: white;
    color:var(--blue-gradient);
`;

const Message = styled.div`
    display: flex;
    flex-direction: column-reverse;
  align-items: ${props => props.incomingMessage ? 'flex-start' : 'flex-end'};
  margin-bottom:1em;
`;

const MessageRow = styled.div`
    display: flex;
    align-items: flex-end; // This will push TimeStamp to the bottom
    transition: .3s ease-in-out all;
`;

const MessageStack = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

const MessageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6em;
    margin-bottom: 5px;
`;

const AuthorName = styled.span`
    margin-right: auto;
    color: grey;
`;

const TimeStamp = styled.span`
    margin-left: 5px; // space between the message bubble and timestamp
    margin-right: 5px;
    align-self: flex-end; // this will push the timestamp to the bottom when inside a flex container
    color: grey;
    font-size: 0.6em; // adjust this based on your preference
    white-space: nowrap; // to ensure timestamp stays on one line
`;

const Conversation = () => {
    const { socket, user, isTextareaFocused, loadingMessages } = useChat();
    const { messages } = useMessages();
    const chatConversation = useRef(null);
    const [clickedMessage, setClickedMessage] = useState(null);
    //const [playingAudio, setPlayingAudio] = useState(null);
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
    
    // auto scroll to bottom on new message recieve / sent
    // ... this doesnt seem to work if the chatform input has focus .. particularly if user has scrolled up and down a bit already ..
    useEffect(() => {
        chatConversation.current.scrollTo(0, chatConversation.current.scrollHeight);   
    //  .scrollTop += adjustment;
        // set a timeout to give components time to rerender?
        //console.log("scrolled to", chatConversation.current.scrollHeight);
    }, [messages]);

    
    // OBSOLETE : Code for requesting and playing generated tts audio
    const generateTtsRequest = async (data) => {
        //inviteToRoom(invitedUserId, user.id, user.username, currentRoom.id);
        const { text, voiceId, messageKey } = data;
        
        // send via socket ..
        console.log("Emitting generate-tts ...", messageKey);
        socket.emit('generate-tts', data);
        
        setCurrentlyPlaying(messageKey);
    };

    // let base64Queue = [];
    // let sourceQueue = [];
    // let nextPlayTime = 0;
    
    // capture websocket event
    // useEffect( () => {
    //
    //     // const audioContext = new AudioContext();
    //     //
    //     // const base64ToArrayBuffer = (base64) => {
    //     //     const binaryData = atob(base64);
    //     //     const arrayBuffer = new ArrayBuffer(binaryData.length);
    //     //     const uint8Array = new Uint8Array(arrayBuffer);
    //     //
    //     //     for (let i = 0; i < binaryData.length; i++) uint8Array[i] = binaryData.charCodeAt(i);
    //     //
    //     //     return { arrayBuffer, length: uint8Array.length };
    //     // }
    //     //
    //     // const createAudioBuffer = (arrayBuffer, length) => {
    //     //     const data = new DataView(arrayBuffer);
    //     //
    //     //     const audioBuffer = audioContext.createBuffer(1, length / 2, 44100);
    //     //     const channelData = audioBuffer.getChannelData(0);
    //     //
    //     //     for (let i = 0; i < data.byteLength; i += 2) {
    //     //         try{
    //     //             const sample = data.getInt16(i, true);
    //     //             channelData[i / 2] = sample / 32768;
    //     //         }catch(err){
    //     //             console.log('createAudioBuffer : exception : ', err);
    //     //         }
    //     //     }
    //     //
    //     //     return audioBuffer;
    //     // }
    //     //
    //     // const decode = () => {
    //     //     const base64 = base64Queue.shift();
    //     //    
    //     //     if (!base64) return;
    //     //    
    //     //     console.log("decode called ..");
    //     //
    //     //     const { arrayBuffer, length } = base64ToArrayBuffer(base64);
    //     //     const audioBuffer = createAudioBuffer(arrayBuffer, length);
    //     //
    //     //     const source = audioContext.createBufferSource();
    //     //     source.buffer = audioBuffer;
    //     //     source.connect(audioContext.destination);
    //     //
    //     //     sourceQueue.push(source);
    //     //
    //     //     if (nextPlayTime < audioContext.currentTime) nextPlayTime = audioContext.currentTime;
    //     //
    //     //     schedulePlaySource(source);
    //     //
    //     //     nextPlayTime += audioBuffer.duration;
    //     //
    //     //     decode();
    //     // }
    //     //
    //     // const schedulePlaySource = (source) => {
    //     //     source.start(nextPlayTime);
    //     //     source.addEventListener('ended', () => sourceEnded());
    //     // }
    //     //
    //     // const sourceEnded = () => {
    //     //     sourceQueue.shift();
    //     //     if (!sourceQueue.length) console.log('Audio finished playing');
    //     // }
    //     //
    //     // const endAudio = (data) => {
    //     //     const parsedMessage = JSON.parse(data);
    //     //     if (parsedMessage.status === 'finished') {
    //     //         console.log("tts finished.");
    //     //         //setPlayingAudio(null);
    //     //     }
    //     // }
    //
    //     // Convert base64 to Blob
    //     const base64ToBlob = (base64, contentType) => {
    //         const binaryData = atob(base64);
    //         const bytes = new Uint8Array(binaryData.length);
    //         for (let i = 0; i < binaryData.length; i++) {
    //             bytes[i] = binaryData.charCodeAt(i);
    //         }
    //         return new Blob([bytes], { type: contentType });
    //     };
    //
    //    
    //     let audioQueue = [];
    //     let currentAudio = null;
    //     let nextAudio = null;
    //     let isPlaying = false;
    //
    //     const playNextAudioChunk = async () => {
    //         if (audioQueue.length === 0) {
    //             isPlaying = false;
    //             console.log('ending-tts for ', currentlyPlaying);
    //             socket.emit('ending-tts', currentlyPlaying);
    //             return;
    //         }
    //
    //         isPlaying = true;
    //         const audioUrl = audioQueue.shift();
    //         currentAudio = new Audio(audioUrl);
    //         await currentAudio.play();
    //         currentAudio.onended = () => {
    //             playNextAudioChunk();
    //             //currentAudio = null; // Clear the current audio
    //         };
    //
    //         // Preload the next audio chunk
    //         if (audioQueue.length > 0) {
    //             const nextAudioUrl = audioQueue[0]; // Peek at the next URL in the queue
    //             nextAudio = new Audio(nextAudioUrl);
    //             nextAudio.load(); // Start loading the next audio
    //         }
    //
    //         // if (nextAudio) {
    //         //     currentAudio = nextAudio;
    //         //     nextAudio = null;
    //         //     isPlaying = true;
    //         //     await currentAudio.play();
    //         // } else {
    //         //     currentAudio = null;
    //         // }
    //     };
    //    
    //     const queueAudioChunk = async (audioData) => {
    //         //const { audio, status } = audioData.data;
    //         const parsedMessage = JSON.parse(audioData);
    //        
    //         console.log("playTTSAudio called ..");
    //         //console.log("audio : ", parsedMessage.audio);
    //
    //         if(parsedMessage.audio){
    //             //console.log("adding tts to b64 queue ..");
    //             //base64Queue.push(parsedMessage.audio);
    //             // here you can choose when to start decoding and playing the audio. For example "if (base64Queue.length > 4) decode();"
    //             // if (base64Queue.length > 4) {
    //             //     console.log("b64queue > 4 , decoding ..");
    //             //     decode();
    //             // }
    //             const audioBase64 = parsedMessage.audio;
    //             const audioBlob = base64ToBlob(audioBase64, 'audio/mpeg');
    //             const audioUrl = URL.createObjectURL(audioBlob);
    //             audioQueue.push(audioUrl);
    //             //nextAudio = new Audio(audioUrl);
    //             //nextAudio.oncanplaythrough = playNextAudioChunk;  // Play when ready
    //            
    //             if (!isPlaying) {
    //                 await playNextAudioChunk();
    //             }
    //            
    //             // const audio = new Audio(audioUrl);
    //             // await audio.play();
    //             // audio.onended = () => {
    //             //     // Handle the end of the audio playback
    //             //     console.log('Audio finished playing');
    //             // };
    //         }else{
    //             //console.log("no tts audio.");
    //         }
    //     };
    //    
    //     if(socket){
    //         socket.on('receive-tts', queueAudioChunk);
    //         //socket.on('tts-ended', endAudio)
    //     }
    //
    //     return () => {
    //         socket.off('receive-tts');
    //     }
    //    
    // }, [socket, user]);
    
    
    return (
        <ConversationContainer ref={ chatConversation } >
            {
                loadingMessages ? 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <BeatLoader color="#3c95f4" loading={loadingMessages} size={15} />
                </div>
                :

                [...messages].reverse().map((m, index) => {
                    const { text, author, author_language, author_avatar, socket_id, id, translations, time, voiceId, type } = m;
                    const isBot = (author === 'BOT');
                    //const incomingMessage = (socket_id !== socket.id); // or author !== currentUser.username
                    const incomingMessage = author !== user.username;
                    
                    return isBot ?
                        <BotMessage> { text } </BotMessage>
                    :
                    (
                        <MessageContainer key={`message-${index}-${messages.length}`} incomingMessage={ incomingMessage } translations={translations}>
                            
                                <UserProfile isImage={!isBot} content={author_avatar} />
                                
                                <Message incomingMessage={ incomingMessage }>
                                    
                                    <MessageRow>
                                        {!incomingMessage && <TimeStamp>{new Date(time).toLocaleTimeString()}</TimeStamp>}
                                        <MessageStack>
                                            <MessageContent
                                                key={`${id}-${messages.length}`}
                                                text={text}
                                                incomingMessage={ incomingMessage } 
                                                messageColor={ languageConfig[author_language]?.color ?? languageConfig['English'].color }
                                                textColor= { languageConfig[author_language]?.textColor ?? languageConfig['English'].textColor }
                                                //onClick={() => setClickedMessage(clickedMessage === id ? null : id)}
                                                //clickedMessage={ clickedMessage === id }
                                                //clickedMessage={true}
                                                clickedMessage={!m.type ?? true }
                                                messageType={type}
                                            />

                                            {  Object.entries(translations)
                                                                            .filter(([language, translation]) => language !== user.language)
                                                                            .map(([language, translation], i, array) => (
                                                <MessageContent 
                                                    text={translation}
                                                    key={`${id}-${language}-${messages.length}`} 
                                                    messageKey={`${id}-${language}`}
                                                    incomingMessage={ incomingMessage } 
                                                    messageColor={ languageConfig[language]?.color ?? languageConfig['English'].color }
                                                    textColor={ languageConfig[language]?.textColor ?? languageConfig['English'].textColor }
                                                    isTranslation
                                                    voiceId={ voiceId }
                                                    isLast={i === array.length - 1}
                                                    isFirst={i === 0}
                                                    showAudio={ type !== 'temp'}
                                                    onPlayAudio={generateTtsRequest}
                                                />
                                            ))}
                                        </MessageStack>

                                        { (incomingMessage && !m.type) && <TimeStamp>{new Date(time).toLocaleTimeString()}</TimeStamp> }
                                    </MessageRow>

                                    <MessageHeader>
                                    {
                                        incomingMessage && <AuthorName>{ author }</AuthorName>
                                    }
                                    </MessageHeader>
                                    
                                </Message>

                        </MessageContainer>
                    );
                })
            }
        </ConversationContainer>
    );
};

export default Conversation;