import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
    height: ${props => props.size || '100%'};
    border-radius: 0.7em;
    display: block;  // This ensures there's no extra space at the bottom

    // height: 3vw;
    // width: 3vw;
    // border-radius: 20px;
    // object-fit: cover;
`;

const Avatar = ({ src, alt = "User Avatar", size }) => {
    return (
        <Image src={src} alt={alt} size={size} />
    );
};

export default Avatar;
