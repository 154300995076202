import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { PasswordInputContainer, PasswordToggleIcon } from "../styled/PasswordInput";
import TopBarNav from '../styled/Topbar';
import { ClipLoader } from 'react-spinners';
import { fetchLanguages } from "../context/LanguageUtility";
import { GoogleLogin } from '@react-oauth/google';
import {useChat} from "../context/ChatProvider";
import { useNavigate, Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_SERVER;

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h1 {
      margin-bottom: 3rem;
      color:white;
    }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid #ddd;
    position: relative;

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 60px;
        border: none;
        border-radius: 5px;
        //background: var(--blue-active-color);
        //transition: .3s ease-in-out opacity, box-shadow;
        background-color:#007BFF;
        cursor: pointer;
        width:250px;

        &:hover {
            //opacity: 0.85;
            //box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
            background-color: #0056b3;
        }
    }
`;

const Input = styled.input`
    width: 250px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.7em;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
  -webkit-appearance: none;

  @media (max-width: 768px) {
    padding: 1.1em; // Increase padding for mobile
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 40px 20px 40px;
  border-radius: 10px;
  background-color: #f3f3f3;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 20px;

  p {
    margin-bottom:20px;
  }
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  
  font-weight: bold;  
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: #FF5C5C;  // Reddish color
    margin: 0;  // Prevents changing the size of the form
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px; /* This provides space between the checkbox and text */
  font-size: 0.9em; /* This reduces the font size a bit */
`;

const StyledCheckbox = styled.input`
  margin: 0; /* This removes any default margin */
`;

const StyledInput = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Signup = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [languageConfiguration, setLanguageConfiguration] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [customLanguage, setCustomLanguage] = useState("");
  const [language, setLanguage] = useState("default");
  const { setUser, setToken } = useChat();
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  // const supportedLanguages = Object.keys(languageConfig).sort();
    useEffect(() => {
        fetchLanguages(setSupportedLanguages, setLanguageConfiguration);
    }, []);

    const checkCustomLanguage = async (language) => {
    
        // 1. call webserver endpoint to do validation 
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/lang/c`, {customLanguage:language}, { withCredentials: true });
    
        // if not valid, update error message appropriately
        if(!response.data.success){
            if(response.data.suggestion) {
                setErrorMessage(`Did you mean '${response.data.suggestion}'?`);
            }else{
                setErrorMessage("Sorry we don't recognize that as a language.");
            }
        }
    
        return response.data.success;
    }

    function getUsernameFromEmail(email) {
        const parts = email.split('@');
        if (parts.length > 1) {
            return parts[0];
        } else {
            // Handle the case where the email does not contain an '@' sign
            return email;
        }
    }
    
    const onSubmit = async (data) => {
        const { username, password, email, language, termsAndConditions } = data;
        
        setIsLoading(true);
        
        // clear old error messages
        setErrorMessage("");

        if(language === 'Other'){
            try{
                const isCustomLanguageValid = await checkCustomLanguage(customLanguage);
                if(!isCustomLanguageValid){
                    setIsLoading(false);
                    return;
                }
            }catch(error){
                console.log(error);
                setErrorMessage("Sorry we don't recognize that as a language.");
                setIsLoading(false);
                return;
            }
        }
        
        let selectedLanguage = (language === 'Other' ? (customLanguage.charAt(0).toUpperCase() + customLanguage.slice(1)) : language);
        
        // automatically set username based on email address
        let displayName = getUsernameFromEmail(email);
        
        try {
            const response = await axios.post(`${baseUrl}/signup`, { username:displayName, password:password, email:email, language:selectedLanguage }, { withCredentials: true });
            
            if (response.data.success) {
                setMessage(response.data.message);
                setIsSuccess(true);
            } else {
                setMessage(response.data.message);
                setIsSuccess(false);
            }
        } catch (error) {
            console.error(error);
            setMessage("Signup failed. Please try again");
            setIsSuccess(false);
        }
        
        setIsLoading(false); 
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleLanguageChange = (e) => {
        const value = e.target.value;
        setCustomLanguage(value);
    };

    // Google OAuth handling
    const googleSuccess = async (response) => {
        try {
            const idToken = response.credential; // Extract the ID token from Google response

            //console.log(response);
            //console.log('Google success! token=', idToken);

            // Send the ID token to your backend for verification
            const backendResponse = await axios.post(`${process.env.REACT_APP_SERVER}/v/gtoken`,
                { idToken: idToken },
                { withCredentials: true }
            );

            const { token, user } = backendResponse.data;

            if (token && user) {
                setToken(token);
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', JSON.stringify(token));
                sessionStorage.setItem('userLoggedInWithGoogle', "TRUE");
                //setErrorMessage(null);
                // NOTE : socket.io connection is made in ChatProvider once User and Token is set
                navigate('/chat');
            } else {
                // Handle the case where the ID token is not verified
                console.error("Invalid Google ID token / Unable to authenticate");
                //setErrorMessage("Failed to login with Google.")
            }
        } catch (error) {
            //console.error("Error handling Google login", error);
            //setErrorMessage("Failed to login with Google.")
        }
    };

    const googleError = (response) => {
        //console.error("Google Login Error", response);
        //setErrorMessage("Failed to login with Google.")
    };

return (
  <SignupContainer>
    <TopBarNav />
      
    <FormContainer>
    
    {isLoading && 
      <SpinnerOverlay>
          <ClipLoader size='25px' color='#3c95f4' />
      </SpinnerOverlay>
    }
    
    {!isSuccess ? 
    (
    <>
    <p>{t('SignupIntroText')}</p>

        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
            buttonText={t('GoogleSignin')}
            width="250"
            height="45"
            onSuccess={googleSuccess}
            onError={googleError}
            cookiePolicy={'single_host_origin'}
        />
        
    <p>- or -</p>
        
    <Form onSubmit={handleSubmit(onSubmit)}>
      
      <Input
        inputMode="email"
        maxLength={70}
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        type="email"
        placeholder={t('RegisterPageEmailText')}
      />
      {errors.email && <ErrorText>{t('SignupEmailValidate')}</ErrorText>}

      <PasswordInputContainer>
          <Input 
              type={showPassword ? "text" : "password"} 
              placeholder={t('SignupPasswordText')}
              {...register("password", {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{7,}$/
              })}
              maxLength={50}
          />
          <PasswordToggleIcon onClick={togglePasswordVisibility}>
              {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
          </PasswordToggleIcon>
      </PasswordInputContainer>
      {errors.password && <ErrorText>Password must:<br/>- be at least 7 characters long<br/>- contain at least one upper case letter <br/>- one lower case letter <br/>- contain a number</ErrorText>}

      {/*<Input*/}
      {/*  {...register("username", { */}
      {/*    required: true,*/}
      {/*    pattern: /^[a-zA-Z0-9_]+$/  */}
      {/*  })}*/}
      {/*  placeholder={t('SignupUsernameText')}*/}
      {/*  maxLength={20}*/}
      {/*/>*/}
      {/*{errors.username && <ErrorText>{t('SignupUsernameValidate')}</ErrorText>}*/}

      <Select 
          {...register("language", { required: true, validate: value => value !== "default" })}
          value={language}
          onChange={e => setLanguage(e.target.value)}
      >
        <option value="default">{t('SignupLanguageSelectDefault')}</option>
        {supportedLanguages.map((language, index) => (
            <option key={index} value={language}>
            {t(language)}
            </option>
        ))}
          <option value="Other">Other</option>
      </Select>
      {language === 'Other' && (
        <Input
            type="text"
            placeholder="Specify your language"
            value={customLanguage}
            onChange={e => handleLanguageChange(e)}
            required={language === 'Other'} // Only required when 'Other' is selected
        />
      )}
      {errors.language && <ErrorText>{t('SignupLanguageValidate')}</ErrorText>}
      { errorMessage && <ErrorText>{errorMessage}</ErrorText>}
  
      <StyledLabel>
        <StyledCheckbox 
          type="checkbox" 
          {...register("termsAndConditions", { required: true })}
        />
        <span dangerouslySetInnerHTML={{ __html: t('IAgreeToTheTermsAndConditions') }} />
      </StyledLabel>
      {errors.termsAndConditions && <ErrorText>{t('TermsAndConditionsValidationMessage')}</ErrorText>}

      <Button type="submit">
      {t('CreateWithEmail')}
      </Button>
    </Form></>) : null}

    {message && 
      <MessageContainer>
         <span dangerouslySetInnerHTML={{ __html: message }} />
      </MessageContainer>
    }

    </FormContainer>
  </SignupContainer>

  );
}

export default Signup;
