import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none; // ensure user cannot close this by clicking outside the modal, they must click the close button
`;

const ModalContainer = styled.div`
    width: 80%;                 
    max-width: 600px;           
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;         
    display: flex;              
    flex-direction: column;    // Stack children vertically
    gap: 20px;                 // Gap between message and buttons
    color: #000;
    align-items: center;
    pointer-events: auto; // ensures that they can still close the modal using the close button

    & > p {
        margin: 0;              
    }
`;

const ModalHeader = styled.h2`
  //margin-bottom: 20px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
  }
`;

const ModalBody = styled.p`
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  text-align: center;

  & button {
    padding: 5px 10px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const Button = styled.button`
  max-width: 150px;           // Limit the button width
  padding: 10px 20px;         // Add some horizontal padding
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 10rem;
  height: 4rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const redirectToStripeCheckout = (userId, userEmail) => {
    let locale = localStorage.getItem('i18nextLng');
    window.location.href = `${process.env.REACT_APP_STRIPE_PAYMENT_URL}?client_reference_id=${userId}&prefilled_email=${userEmail}&locale=${locale}`;
};

const SubscribeModal = ({ title, message, userId, userEmail, onClose }) => {
    const { t } = useTranslation();

    return (
        <ModalBackground onClick={onClose}>
            <ModalOverlay >
                <ModalContainer>
                    <ModalHeader>
                        {title}
                    </ModalHeader>
                    {message}
                    <ModalFooter>
                        <Button onClick={() => redirectToStripeCheckout(userId, userEmail)}>{t("SubformUpgrade")}</Button>
                    </ModalFooter>
                </ModalContainer>
            </ModalOverlay>
        </ModalBackground>
    );
};

export default SubscribeModal;