import styled from "styled-components";

export const ErrorMessage = styled.p`
    color: #ff3333;            // Red color to indicate an error
    font-weight: bold;         // Make it bold
    border: 1px solid #ffcccc; // Border with a lighter red shade
    background-color: #ffe5e5;// Background with a very light red shade
    padding: 10px;             // Add some padding for better appearance
    border-radius: 5px;        // Rounded corners
    margin-bottom: 15px;       // Space below the error message for better separation
    text-align: center;        // Center the error message
    word-wrap: break-word;
    width:15em;
  font-size:1rem;
  margin-left:1.5em;
`;