import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import styled from 'styled-components';
import { useChat } from '../context/ChatProvider';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import PasswordField from './PasswordField';
import { GoogleLogin } from '@react-oauth/google';
import { ErrorMessage } from '../styled/ErrorMessage';
import LineLoginButton from "./LineLoginButton";
//import Signup from "./Signup";

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    //background: ;
    padding: 15px 20px;
    border-radius: 0;
    width: clamp(290px, 18vw, 20%);
    
    & button {
        display: flex;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        background-color: #007BFF;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: #0056b3;
        }
    }
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

// const ErrorHeader = styled.div`
//   color: red;
//   font-size: 0.8rem;
//   width:60%;
// `;

const Button = styled.button`
  width: 100%;  // Full width to match input fields
  padding: 10px 0px;  // Remove horizontal padding to match input fields width
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;  // Display inner elements in a row
  justify-content: center;  // Center inner elements horizontally
  align-items: center;  // Center inner elements vertically

  &:hover {
    background-color: #0056b3;
  }
`;

const LoginSeperator = styled.div`
  color:dimgray;
  font-size: 1em;
  text-decoration: solid;
  width:clamp(290px, 18vw, 20%);
`;

const PasswordResetLink = styled(Link)`
    color: dimgray;
    font-size: 0.8em;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
        color: lightgray;
    }
`;

const SignupContainer = styled.div`
  margin-top:3em;
  font-size:0.8em;
  color: dimgray;
`;

const SignupLink = styled(Link)`
  color: #2070c6;
  font-size: 1em;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #404258;
  }
`;

const Header = styled.div`
  display:flex;
  align-content: center;
  font-size: 0.8em;
  padding: 15px 20px 10px 20px;
  width:clamp(290px, 18vw, 20%);
  //width:250px;
  //margin-bottom: 5px;
  //border-bottom: 1px solid #fff;
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  width: 20em; // Custom width
  // Add more custom styles here
`;


const Login = ({initialMessage}) => {
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const { user, setUser, setToken, joinRoom, fetchRooms, setCurrentRoom, resetContextFor } = useChat();
    const baseUrl = process.env.REACT_APP_SERVER;
    const navigate = useNavigate(); 

    const { t } = useTranslation();
    
    const wasGuest = user?.guest;
    
    //console.log(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);

    useEffect(() => {
        // load initial message if there is one
        if(initialMessage){
            setErrorMessage(initialMessage);
        }
    },[]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);

        const email = inputEmail.current.value;
        const password = inputPassword.current.value;

        try {
        
            if(!email || !password) {
                setIsSubmitting(false);
                return;
            }
            
            // check if logging in from being guest, as we will need to reload the chat
            
            
            const response = await axios.post(`${baseUrl}/login`, { email: email, password: password }, { withCredentials: true });

            const { token, user } = response.data;

            if (token && user) {
                
                //console.log("token + authUser");
                
                setToken(token);
                resetContextFor(user);
                
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', JSON.stringify(token));
                sessionStorage.setItem('userLoggedInWithGoogle', "FALSE");
                setErrorMessage(null);

                // NOTE : socket.io connection is made in ChatProvider once User and Token is set
                
                if(wasGuest){
                    
                    // TODO associate worm chat to user logging in, delete guest account
                    
                    sessionStorage.setItem('currentRoom', JSON.stringify(user.latestRoom));
                    setCurrentRoom(user.latestRoom);
                    await fetchRooms();
                    
                    //navigate('/init-chat');
                    window.location.href = window.location.origin + '/init-chat';
                }
                
                navigate('/chat');

            }else{
               // console.log('no token or authUser returned');
                // something went wrong, display the returned message to the user
                if(response.data.message){
                    setErrorMessage(response.data.message);
                }else{
                    setErrorMessage(t('LoginError'));
                }
            }

        } catch (err) {
            console.log(err);
            setErrorMessage(t('LoginError'));
        }finally{
            setIsSubmitting(false);
        }
    }

    // Google OAuth handling
    const googleSuccess = async (response) => {
        try {
            const idToken = response.credential; // Extract the ID token from Google response

            //console.log(response);
            //console.log('Google success! token=', idToken);
            
            // Send the ID token to your backend for verification
            const backendResponse = await axios.post(`${process.env.REACT_APP_SERVER}/v/gtoken`,
                { idToken: idToken },
                { withCredentials: true }
            );
            
            const { token, user } = backendResponse.data;

            if (token && user) {
                setToken(token);
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', JSON.stringify(token));
                sessionStorage.setItem('userLoggedInWithGoogle', "TRUE");
                setErrorMessage(null);
                // NOTE : socket.io connection is made in ChatProvider once User and Token is set
                
                // Check for last room and join
                // if(user.latestRoom){
                //     joinRoom(user.latestRoom.id);
                // }
                
                
                navigate('/chat');
            } else {
                // Handle the case where the ID token is not verified
                console.error("Invalid Google ID token / Unable to authenticate");
                setErrorMessage("Failed to login with Google.")
            }
        } catch (error) {
            //console.error("Error handling Google login", error);
            setErrorMessage("Failed to login with Google.")
        }
    };

    const googleError = (response) => {
        //console.error("Google Login Error", response);
        setErrorMessage("Failed to login with Google.")
    };

    return (
        <>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <LoginContainer onSubmit={ handleSubmit }>
                <Input type="text" inputMode="email" placeholder={t('HomepageEmail')} ref={ inputEmail } maxLength={70} />
                <PasswordField placeholder={t('HomepagePassword')} reference={inputPassword} maxLength={50} />
                <Button type="submit" disabled={isSubmitting}>
                    {t('LoginButton')}
                </Button>
            </LoginContainer>
            <LoginSeperator>- OR -</LoginSeperator>
            <Header>
                <LineLoginButton text={t('LineSignIn')} />
            </Header>
            <Header>
                {/* Google Login Button */}
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                    buttonText={t('GoogleSignin')}
                    width="250"
                    height="45"
                    onSuccess={googleSuccess}
                    onError={googleError}
                    cookiePolicy={'single_host_origin'}
                />
            </Header>
           
            <PasswordResetLink to="/forgot-password">{t('ForgotPasswordLink')}</PasswordResetLink>
            
            <SignupContainer>
                {t('DontHaveAccount')} <SignupLink to="/signup">{t('AccountSignUp')}</SignupLink>
            </SignupContainer>
            
        </>
    );
};

export default Login;