import React from 'react';
import PageTemplate from './PageTemplate';

const Privacy = () => {

  return (
    <PageTemplate>

          <h1>Privacy Policy</h1>
          <p>Effective Date: 10 September 2023</p>

          <p>Welcome to BabbleOn Chat. Protecting your private information is our priority. This Privacy Policy applies to the BabbleOn Chat and governs data collection and usage. By using the BabbleOn Chat, you consent to the data practices described in this policy.</p>

          <h2>Information We Collect</h2>
          <p>We collect a minimal amount of information to facilitate the service we provide. When you log in using Google OAuth and LINE Login, we collect your email address and basic profile information such as your name and profile picture. This information is needed to link to an account in BabbleOn Chat and to manage your account.</p>

          <h2>How We Use Google and LINE User Data</h2>
          <p>Your Google and LINE user data is used exclusively for account creation, authentication, and profile setup within BabbleOn Chat. We do not use this data for any other purpose nor do we share it with third parties for advertising or marketing.</p>

          <h2>Storage and Security of Google and LINE User Data</h2>
          <p>The data we collect from Google or LINE is stored securely and treated with the utmost care. Your data is encrypted both in transit and at rest. We only retain your Google user data for as long as your account is active or as needed to provide you our services.</p>

          <h2>Access and Sharing of Google and LINE User Data</h2>
          <p>We do not share your Google or LINE user data with any third parties, except in cases where it is necessary to provide our service or when legally required. We ensure that any access to this data is strictly controlled and secured.</p>

          <h2>Revocation of Google LINE User Data</h2>
          <p>If you wish to revoke our access to your Google or LINE user data, you may do so through your Google or LINE account settings. Upon revocation, we will cease to use your Google or LINE user data and will delete any stored Google or LINE user data within a reasonable period, in accordance with our data retention policy.</p>

          <h2>Security of Your Information</h2>
          <p>We are committed to ensuring the security of your information. To prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of information, we have put in place physical, electronic, and managerial procedures to safeguard and secure the information we collect online. Specifically, we use the following security measures:</p>

          <p><strong>Secure Communication:</strong> All communications are over HTTPS and encrypted to protect data during transmission.</p>

          <p><strong>Data Encryption:</strong> Conversations are encrypted at rest in the database, offering a higher level of security for your data.</p>

          <p><strong>Third-Party Services:</strong> Messages entered by users may be translated by a third-party service (OpenAI). This communication is secure and encrypted to ensure data privacy.</p>

          <h2>Disclosure of Your Information</h2>
          <p>We may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on BabbleOn Chat; (b) protect and defend the rights or property of BabbleOn Chat; and/or (c) act under exigent circumstances to protect the personal safety of users of BabbleOn Chat, or the public.</p>

          <h2>Children Under Thirteen</h2>
          <p>BabbleOn Chat does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>

          <h2>Compliance with Privacy Laws</h2>
          <p>BabbleOn Chat complies with all applicable privacy laws and regulations. We take our responsibility to protect your information seriously and strive to maintain transparency in our data practices.</p>

          <h2>Your Rights and Choices</h2>
          <p>You have the right to access, update, and delete your personal information. If you wish to exercise these rights, please contact us at the provided contact information. We are committed to facilitating your rights in accordance with the law.</p>

          <h2>Changes to this Policy</h2>
          <p>BabbleOn Chat reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, or by placing a prominent notice on our site.</p>

          <h2>Contact Information</h2>
          <p>BabbleOn Chat welcomes your questions or comments regarding this Privacy Policy. If you believe that BabbleOn Chat has not adhered to this Privacy Policy, please contact us at info@babbleon.ca.</p>


    </PageTemplate>
  );
};

export default Privacy;
