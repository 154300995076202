import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { MdSend } from "react-icons/md";
import useChatActions from '../hooks/useChatActions';
import { useChat } from '../context/ChatProvider';
import { useTranslation } from "react-i18next";
import { AiFillWechat } from "react-icons/ai"
import { Link } from 'react-router-dom';

const MessageForm = styled.form`
    position: sticky;
    bottom: 0;
    //padding: 0.5vw 0;
    display: flex;
    align-items: center;
    height: 10%;
    flex:none;

    //border-top: 1px solid rgba(0, 0, 0, 0.08);

    & input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
    }
  
    & a {
      position:absolute;
    }

    & textarea {
        flex: 1;
        height: 90%;
        width: 100%;
        border: none;
        resize: none; // Disables manual resize of the textarea
        overflow-y: auto; // Allows vertical scrolling if necessary
        font-size: 0.8rem; // Adjust as necessary to match your input style
        line-height: 1.5; // Adjust as necessary to match your input style
        //padding: 0.5rem; // Adjust padding as necessary to match your input style
        //margin-left:0.3rem;
        //background:lightgrey;
        border-radius:15px; //0px 0px 15px;
        //margin-right:2rem;
        padding:10px 60px 10px 10px;
        color:var(--blue-gradient);
      
        @media (max-width: 820px) {
            font-size: 1rem; 
        }
    }
`;

const MessageInputContainer = styled.div`
  position: relative;
  width: 100%;
  height:90%;
  margin-top:0.5rem;
  margin-left:0.3rem;
  margin-right:0.3rem;

  display:flex;
  //align-items: center;
  //justify-content: space-between;
  //gap:0.1em;
`;

const SendButtonContainer = styled.div`
    display: ${ props => props.device === 'mobile' ? 'none' : 'flex'};
    //flex: ${ props => props.flex ? props.flex : '1'};
    align-items: flex-end;
    flex-direction:column;

    @media (max-width: 820px) {
        display: flex;
    }

    & a, button { 
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${ props => props.padding ? props.padding : '0.8em'};
        width: 15%;
        height: 90%;
        border: none;
        border-radius: ${ props => props.borderRadius ? props.borderRadius : '1em'};
        background: ${ props => props.active ? 'rgb(0, 123, 255)' : 'var(--secondry-color-dark-palette)' };
        // box-shadow: ${ props => props.active ? 'rgba(32, 112, 198, 0.7) 0 0 10px' : null };
        aspect-ratio: 1/1;
        transition: .3s ease-in-out all;
        cursor: pointer;

      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
      
        //@media (max-width: 820px) {
        //    //padding: 0.7em;
        //    width:5em;
        //    height:5em;
        //}
    }
    
    & svg {
        //fill: ${ props => props.active ? '#fff' : '#737373'};
        fill: white;
        transition: .3s ease-in-out all;
    }
    
    & button:hover, a:hover {
        //background: var(--blue-active-color);
      background: ${props => props.active ? 'rgb(0, 123, 255)' : 'var(--secondry-color-dark-palette)'};
        //box-shadow: rgba(32, 112, 198, 0.7) 0 0 10px;
        //opacity: 0.8;
    }

    & a:hover svg {
        fill: #fff;
    }
`;

const SendIcon = styled(MdSend)`
  position: absolute;
  top: 45%;
  //right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  color:var(--blue-gradient);
  //font-size: 1.5rem;
  
  margin-left:90%;
  height: 100%;
  width:9%;
    //cursor: pointer;
    //margin-right:0.3em;
  
    //font-size: 2em;
    //margin-left: auto;  // push it to the rightmost side
`;

const ChatForm = () => {
    const inputRef = useRef(null);
    const { sendMessage } = useChatActions();
    const { currentRoom, setIsMessageSending, chatInputMode, setIsTextareaFocused, isMobile, socket, user, blockSending, getRoomLanguages } = useChat();
    const { t } = useTranslation();
    const [typing, setTyping] = useState(false);
    const typingTimeoutId = useRef(null);
    
    const handleTyping = useCallback(() => {
        if (!typing) {
            socket.emit('typing', { roomID: currentRoom.id, userID: user.id, username: user.username, avatar:user.avatar, language:user.language });
            setTyping(true);
        }
        clearTimeout(typingTimeoutId.current);  
        typingTimeoutId.current = setTimeout(() => {
            setTyping(false);
        }, 3000); // Reset the typing status after 3 seconds of inactivity
    }, [typing, currentRoom.id, user.id, socket]);

    
    const sendButtonClickedRef = useRef(false);
    
    const onSubmit = (e) => {
        e.preventDefault();

        sendButtonClickedRef.current = true;
        
        if(blockSending) {
            console.log('quote reached - sending blocked.');
            return;
        }
        
        if(!inputRef.current.value) return;

        // Set the message sending state to true when sending a message
        setIsMessageSending(true);

        let message = inputRef.current.value;

        // clear immediately, otherwise there is sometimes a delay
        inputRef.current.value = '';
        inputRef.current.focus();
        
        // Simulate the sendMessage asynchronous action
        sendMessage(message, currentRoom.id)
            .catch(error => {
                console.log(error);
                setIsMessageSending(false);
            });
    }

    // ensures chatform is auto scrolled to when onscreen keyboard is shown (particularly on mobile)
    useEffect(() => {

        // TODO: assing inputRef.current to a variable here, and use in cleanup as well

        const handleFocus = () => {

            if(isMobile){
                setIsTextareaFocused(true);
                window.scrollTo(0, document.body.scrollHeight);
            }

            // if(isMobile){
            //     setIsTextareaFocused(true);
            //    
            //     const isSafariMobile = /iP(ad|od|hone)/i.test(navigator.userAgent) && /WebKit/i.test(navigator.userAgent) && !/CriOS/i.test(navigator.userAgent);
            //    
            //     // setTimeout(() => {
            //     //     // Scroll to a position that considers the onscreen keyboard
            //     //     const scrollPosition = document.activeElement.getBoundingClientRect().top + window.scrollY - window.innerHeight / 2;
            //     //     window.scrollTo(0, scrollPosition);
            //     // }, 300);
            //     // Use a timeout to allow UI to adjust
            //     setTimeout(() => {
            //         if(isSafariMobile){
            //             // Adjust this value as needed for Safari Mobile
            //             const safariScrollAdjustment = 250; // Example value
            //
            //             const element = inputRef.current;
            //             if (element) {
            //                 const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            //                 //window.scrollTo(0, elementPosition - safariScrollAdjustment);
            //                window.scrollTo(0, elementPosition - safariScrollAdjustment);
            //             }
            //         }else{
            //             // For other mobile browsers
            //             const element = inputRef.current;
            //             if (element) {
            //                 element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            //             }
            //         }
            //     }, 300);
            // }
            
            //window.scrollTo(0, document.body.scrollHeight);
            
            // setTimeout(() => {
            //     inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            // }, 300);  // Adjust the timeout as necessary
        };

        const handleBlur = () => {
            if (sendButtonClickedRef.current) {
                sendButtonClickedRef.current = false;
                return;
            }
            setIsTextareaFocused(false);
            window.scrollTo(0,0);
        };
    
        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            inputRef.current.addEventListener('blur', handleBlur);
        }
    
        // Cleanup
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('focus', handleFocus);
                inputRef.current.removeEventListener('blur', handleBlur);
            }
        };

    }, [setIsTextareaFocused]);
    
    return (
        <MessageForm onSubmit={ onSubmit }>
 
            <MessageInputContainer>
                
                    <textarea
                        disabled={blockSending}
                        inputMode={chatInputMode}
                        placeholder={t('ChatFormText')}
                        ref={inputRef}
                        maxLength={250}
                        rows={1}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                onSubmit(e);
                            }
                        }}
                        onChange={(e) => {
                            handleTyping();
                        }}
                    />
                    
                    <SendButtonContainer active={true} size='5em' onClick={onSubmit}>
                        <Link>
                            <AiFillWechat size='2em' />
                        </Link>
                    </SendButtonContainer>
                    {/*<SendIcon size="2em" onClick={onSubmit} />*/}
                
            </MessageInputContainer>
            
        </MessageForm>
    );
};

export default ChatForm;