import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const VerificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    //background-color: #f3f3f3;
    background-color: #3c95f4;  // Feel free to adjust the color or use a gradient.

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background: var(--blue-active-color);
        transition: .3s ease-in-out opacity, box-shadow;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const Message = styled.p`
    color: ${props => props.success ? "green" : "red"};
    font-size: 1rem
    text-align: center;
    //margin-bottom: 20px;
`;

const ReturnLink = styled.a`
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
        color: lightgray;
    }
`;

const EmailVerificationPage = () => {
    const [verificationStatus, setVerificationStatus] = useState(null);
    //const navigate = useNavigate();

    useEffect(() => {
        // Fetch the token from URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        // Call the /verify-email endpoint to verify the token
        axios.post(`${process.env.REACT_APP_SERVER}/verify-email?token=${token}`)
            .then(response => {
                setVerificationStatus({
                    success: true,
                    message: response.data
                });
            })
            .catch(error => {
                setVerificationStatus({
                    success: false,
                    message: error.response ? error.response.data : "An error occurred. Please try again later."
                });
            });
    }, []);

    return (
        <VerificationContainer>
            {verificationStatus && (
                <>
                <MessageContainer>
                    <Message success={verificationStatus.success}>
                        {verificationStatus.message}
                    </Message>
                </MessageContainer>
                <br />
                <ReturnLink href="/">Return to homepage</ReturnLink>
                </>
            )}
        </VerificationContainer>
    );
}

export default EmailVerificationPage;
