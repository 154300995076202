import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useChat} from "../context/ChatProvider";
import LoadingScreen from "./LoadingScreen";

const InitChat = () => {
    const navigate = useNavigate();
    const { socket, user, fetchRooms, rooms, currentRoom, setCurrentRoom } = useChat();
    
    useEffect(() => {
        const setup = async () => {

            try{
                await fetchRooms();
                
                let existingRoom;
                if(!currentRoom){
                    existingRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
                    if(!existingRoom) {
                        //console.log('No current Room - so using rooms ..');
                        existingRoom = rooms[0];
                        setCurrentRoom(existingRoom);
                        sessionStorage.setItem('currentRoom', JSON.stringify(rooms[0]));
                    }
                }else{
                    existingRoom = currentRoom;
                }

                if(socket && existingRoom){
                    socket.emit('join-room', { roomID:existingRoom.id, roomLanguages:existingRoom.languages, userJoining:user } );
                }

                navigate('/chat');
                
                
                // prevent this running twice if we already setup a user
                // let existingRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
                //
                // console.log(existingRoom);
                //
                // //console.log("INIT CHAT emit join-room ....");
                // await fetchRooms();
                //
                // if(!existingRoom) {
                //     existingRoom = currentRoom;
                //     if (!existingRoom){
                //         console.log('No current Room - so using rooms ..');
                //         existingRoom = rooms[0];
                //         setCurrentRoom(existingRoom);
                //         sessionStorage.setItem('currentRoom', JSON.stringify(rooms[0]));
                //     }
                // }
                
                //await joinRoom(existingRoom.id, existingRoom);
                // if(socket && existingRoom){
                //     socket.emit('join-room', { roomID:existingRoom.id, roomLanguages:existingRoom.languages, userJoining:user } );
                // }
                //
                // navigate('/chat');
                
            }catch(error){
                console.log(error);
            }
        }

        setup();

        return () => {};
        
    },[socket]);
    
    return ( <LoadingScreen /> );
}

export default InitChat;