import React, {useState} from 'react';
import styled from 'styled-components';
import TopBarNav from '../styled/Topbar';
import { useTranslation } from "react-i18next";
import {useChat} from "../context/ChatProvider";
import { useNavigate } from 'react-router-dom';
import Login from "./Login";

const WrapperContainer = styled.div`
  display: grid;
  height: 100vh;
  place-items: center;
`;

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  height: 100vh;
  color: white;
  text-align: center;
  padding: 0 1em;
  margin-top: 6em;
  
  h1 {
    font-size: 2.5rem;
    //margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
`;

const Button = styled.a`
  //width: 100%;  // Full width to match input fields
  padding: 20px 20px;  // Remove horizontal padding to match input fields width
  border-radius: 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-size:1.3em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;  // Display inner elements in a row
  justify-content: center;  // Center inner elements horizontally
  align-items: center;  // Center inner elements vertically

  width: clamp(290px, 18vw, 20%);
  margin-bottom:70px;
  margin-top:45px;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonSvgContainer = styled.div`
  display: flex;
  //flex-direction:column;
  align-items: flex-end;
  justify-content: space-between;
  width: fit-content;
  //margin-right:15em;
  //margin-bottom:2.5em;
`;

const FullLogoContainer = styled.div`
  display:flex;
  flex-direction:row;

  h1 {
    font-size: 2.2rem;
    position:absolute;
    margin-left:48px;
    margin-top:0.3em;
    //margin-bottom: 1rem;
  }
`;

const NewLogoContainer = styled.div`
  display:flex;
  flex-direction:row;
  margin-right:7.5em;
`;

const ImageContainer = styled.img`
  width:4.1em;
  height: 4.1em;
  border-radius: 50%;
  border:solid;
`;

const SvgImage = styled.img`
  //display: block;
  //margin-right: 3em;
  width: 90px; // Adjust size as needed
  //height: auto;
  //margin-top: 1rem; // Space below the title
  margin-bottom: 3.3rem;
  margin-top:3.5em;
`;

const ChatIcon = styled.div`
  margin-bottom:5em;
  margin-left:1em;
`;

const HomepagePhraseStyled = styled.p`
  max-width: 30em; // Adjust this value as needed
  overflow-wrap: break-word;
`;

const LinePowered = styled.div`
  width:10px;
  height:10px;
  
  & img {
    width:21px;
    height:18px;
    padding-left:3px;
  }
`;

const SignIn = () => {

    const { t } = useTranslation();
    //const navigate = useNavigate();
    //const [showModal, setShowModal] = useState(false);
    //const {setUser, setToken, setCurrentRoom, setInitJoin, setActiveView, setIsNavOpen} = useChat();

    // ensure we are at top when homepage loads
    window.scrollTo(0, 0);
    

    return (
        <WrapperContainer>
            <TopBarNav />
            <HomePageContainer>

                <ButtonSvgContainer>
                    
                </ButtonSvgContainer>
                
                <Login />

            </HomePageContainer>
        </WrapperContainer>
    );
};

export default SignIn;
