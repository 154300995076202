// LanguageUtils.js
import axios from 'axios';
import { languageConfig } from './LanguageConfig'; // Adjust the path as needed

const setSessionItem = (key, value, ttl) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
};

const getSessionItem = (key) => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        sessionStorage.removeItem(key);
        return null;
    }
    return item.value;
};

export const fetchLanguages = async (setSupportedLanguages, setLanguageConfiguration) => {
    const cachedLanguages = getSessionItem('languages');
    if (cachedLanguages) {
        setSupportedLanguages(Object.keys(cachedLanguages.config).sort());
        setLanguageConfiguration(cachedLanguages.config);
        return;
    }

    try {
        //console.log("Fetching languages ...");
        const response = await axios.get(`${process.env.REACT_APP_SERVER}/lang/supported`, { withCredentials: true });
        if (response.data.success) {
            setSupportedLanguages(Object.keys(response.data.config).sort());
            setLanguageConfiguration(response.data.config);
            setSessionItem('languages', response.data, 900000); // Cache for 15 minutes
        }
    } catch (error) {
        console.error('Error fetching languages:', error);
        setSupportedLanguages(Object.keys(languageConfig).sort());
        setLanguageConfiguration(languageConfig);
    }
};
