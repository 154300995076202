import React from 'react';
import PageTemplate from './PageTemplate';

const Terms = () => {

  return (
    <PageTemplate>
      <p></p>
      <h1>Terms and Conditions</h1>

      <strong>Introduction</strong>
      <p>Welcome to BabbleOn Chat. These Terms and Conditions govern your use of BabbleOn Chat and provide information about the BabbleOn Chat Service, outlined below. When you create an account on BabbleOn Chat or use BabbleOn Chat, you agree to these terms.</p>

      <strong>1. The BabbleOn Chat Service</strong>
      <p>The BabbleOn Chat Service allows you to communicate and interact with other users through chat functionalities. You are responsible for your interactions with other users.</p>

      <strong>2. Privacy</strong>
      <p>Our privacy policy explains how we treat your personal data and protect your privacy when using our Services. By using our Services, you agree that BabbleOn Chat can use such data in accordance with our <a href="/privacy" target='_blank' rel='noopener noreferrer'>privacy policy.</a></p>

      <strong>3. User Conduct</strong>
      <p>You agree not to use the service in any way that is unlawful, harasses another person, is offensive, promotes racism, bigotry, hatred, or physical harm, promotes or provides instructional information about illegal activities, exploits people in a sexual or violent manner, or violates any intellectual property rights.</p>

      <strong>4. Content</strong>
      <p>You are responsible for the content, including text, images, and videos, that you upload or transmit through the BabbleOn Chat Service. You agree that you will not upload or transmit content that is inappropriate, offensive, or in violation of any law.</p>

      <strong>5. Intellectual Property</strong>
      <p>You agree that all content and materials delivered via the BabbleOn Chat Service are owned by babbleon.ai and its licensors. You may not copy, distribute, or create derivative works from this content without express permission from babbleon.ai.</p>

      <strong>6. Account Security</strong>
      <p>You are responsible for safeguarding your account, so use a strong password and limit its use to this account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.</p>

      <strong>7. Limitation of Liability</strong>
      <p>To the maximum extent permitted by law, BabbleOn Chat shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</p>

      <strong>8. Termination</strong>
      <p>We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

      <strong>9. Changes</strong>
      <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at our sole discretion.</p>

      <strong>Contact Us</strong>
      <p>If you have any questions about these Terms, please contact us at info@babbleon.ca</p>

    </PageTemplate>
  );
};

export default Terms;
