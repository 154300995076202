import React from 'react';
import styled from 'styled-components';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none; // ensure user cannot close this by clicking outside the modal, they must click the close button
`;

const ModalContainer = styled.div`
    width: 80%;                 
    max-width: 600px;           
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;         
    display: flex;              
    flex-direction: column;    // Stack children vertically
    gap: 20px;                 // Gap between message and buttons
    color: #000;
    align-items: center;
    pointer-events: auto; // ensures that they can still close the modal using the close button

    & > p {
        margin: 0;              
    }
`;

const ModalHeader = styled.h2`
  //margin-bottom: 20px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
  }
`;

const ModalBody = styled.p`
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  text-align: center;

  & button {
    padding: 5px 10px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const Button = styled.button`
  max-width: 150px;           // Limit the button width
  padding: 10px 20px;         // Add some horizontal padding
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  //transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ModalAlert = ({ title, message, isSuccess, isOpen, onClose, showCloseButton = true }) => {
  const { t } = useTranslation();
  
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          {isSuccess ? <MdCheckCircle color="green" size={24} /> : <MdError color="red" size={24} />}
          {title}
        </ModalHeader>
        {message}
        {showCloseButton && (
          <ModalFooter>
            <Button onClick={onClose}>{t('CloseButton')}</Button>
          </ModalFooter>
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ModalAlert;
