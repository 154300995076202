import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { PasswordInputContainer, PasswordToggleIcon } from "../styled/PasswordInput";
import TopBarNav from '../styled/Topbar';

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 3rem;
        color:white;
      }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid #ddd;
    position: relative;

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        //background: var(--blue-active-color);
        //transition: .3s ease-in-out opacity, box-shadow;
        background-color: #007BFF;
        cursor: pointer;

        &:hover {
            //opacity: 0.85;
            //box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
            background-color: #0056b3;
        }
    }
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 70px 20px 70px;
  border-radius: 10px;
  background-color: #f3f3f3;
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: #FF5C5C;  // Reddish color
    margin: 0;  // Prevents changing the size of the form
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const ResetPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [requestValid, setRequestValid] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [showPassword, setShowPassword] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const { t } = useTranslation();

    const passwordValue = watch("password", "");

    const validateResetRequest = async () => {
        try{
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

            const response = await axios.get(`${process.env.REACT_APP_SERVER}/reset-password/${token}`, { withCredentials: true });

            if(response.data.success){
                setRequestValid(true);
            }else{
                setRequestValid(false);
                setIsError(true);
                setErrorMessage(t('InvalidRequestText'));
            }
        }catch(error){
            //console.log(error);
            setRequestValid(false);
            setIsError(true);
            setErrorMessage(t('InvalidRequestText'));
        }
    }

    const onSubmit = async (data) => {
        const { password } = data;

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        setIsLoading(true);

        try{
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/reset-password`, { password: password, token: token }, { withCredentials: true });

            if(response.data.success){

                // show success message with link to homepage to login
                setShowSuccess(true);
                setIsLoading(false);

            }else{
                setErrorMessage(t('GenericErrorText'));
                setIsError(true);
                setRequestValid(false);
            }

        }catch(error){
            //console.log(error);
            setErrorMessage(t('GenericErrorText'));
            setIsError(true);
            setIsLoading(false);
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    if (!requestValid && !isError) {
        return (
            <SignupContainer>
                <TopBarNav />
                <FormContainer>
                    <p>{t('RestPasswordLandingText')}</p>
                    <Button onClick={validateResetRequest}>{t('ContinueButton')}</Button>
                </FormContainer>
            </SignupContainer>
        );
    }else if(isError){
        return (
            <SignupContainer>
                <TopBarNav />
                <FormContainer>
                    <p>{errorMessage}</p>
                </FormContainer>
            </SignupContainer>
        );
    }else if(showSuccess){
        return (
            <SignupContainer>
                <TopBarNav />
                <FormContainer>
                    <p>{t('PasswordResetSuccessText')}</p>
                </FormContainer>
            </SignupContainer>
        );
    }

    return (
        <SignupContainer>
            <TopBarNav />
            <FormContainer>

                {isLoading && 
                    <SpinnerOverlay>
                        <Spinner />
                    </SpinnerOverlay>
                }

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <PasswordInputContainer>
                        <Input 
                            type={showPassword ? "text" : "password"} 
                            placeholder={t('SignupPasswordText')}
                            {...register("password", {
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{7,}$/
                            })}
                            maxLength={50}
                        />
                        <PasswordToggleIcon onClick={togglePasswordVisibility}>
                            {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                        </PasswordToggleIcon>
                    </PasswordInputContainer>
                    {errors.password && <ErrorText>Password must:<br/>- be at least 7 characters long<br/>- contain at least one upper case letter <br/>- one lower case letter <br/>- contains a number</ErrorText>}

                    <PasswordInputContainer>
                        <Input 
                            type={showPassword ? "text" : "password"} 
                            placeholder={t('RetypePasswordText')}
                            {...register("password-second", {
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}$/ ,
                                validate: value => value === passwordValue || t('PasswordsDontMatchText')
                            })}
                            maxLength={50}
                        />
                        <PasswordToggleIcon onClick={togglePasswordVisibility}>
                            {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                        </PasswordToggleIcon>
                    </PasswordInputContainer>
                    {errors["password-second"] && 
                        (errors["password-second"].type === 'validate' ?
                            <ErrorText>{errors["password-second"].message}</ErrorText> :
                            <ErrorText>Password must:<br/>- be at least 7 characters long<br/>- contain at least one upper case letter <br/>- one lower case letter <br/>- no special characters</ErrorText>
                        )
                    }

                    <Button type="submit">{t('SaveChangesButton')}</Button>
                </Form>
            </FormContainer>
        </SignupContainer>
    );

};

export default ResetPassword;