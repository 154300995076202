import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // npm install uuid to use this
import styled from "styled-components"; // npm install js-cookie to use this

const LineButtonContainer = styled.div`
    display:flex;
    flex-direction: row-reverse;
    width:100%;
    background:#06C755;
    border-radius:5px;
`

const LineButton = styled.button`
  width: 100%;  // Full width to match input fields
  //padding: 10px 0px;  // Remove horizontal padding to match input fields width
  padding-left:27px;
  //border-radius: 5px;
  border: none;
  background-color: #06C755;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border-left:0.5px solid #fff;

  display: flex;  // Display inner elements in a row
  align-items: center;  // Center inner elements vertically
  
  border-radius: 0px 5px 5px 0px;
  
`;

const LineLoginButton = ({text = null, onInit = null, extraState = null}) => {
    const CLIENT_ID = process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID; // Replace with your LINE Channel ID
    const REDIRECT_URI = encodeURIComponent('https://chat.babbleon.ai/auth/line'); // Your callback URL
    //const LINE_AUTH_URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=12345abcde&scope=profile%20openid`;
    
    const initLogin = () => {
        let state = uuidv4(); // Generate a UUID for the state parameter
        if(extraState)
            state = `${state}?${extraState}`;
        //Cookies.set('line_auth_state', state, { expires: 1 / 24, secure: true }); // Store state in a cookie for 1 hour
        sessionStorage.setItem("line_auth_state", state);
        
        const LINE_AUTH_URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${state}&scope=profile%20openid`;
        window.location.href = LINE_AUTH_URL;
    };

    if(!text)
        text = "Sign in with LINE";

    if(!onInit)
        onInit = initLogin; // default to LINE Login if none other specified

    return (
        <LineButtonContainer>
            <LineButton onClick={onInit} >{text}</LineButton>
            <img src="/line-icon.png" alt={text}></img>
        </LineButtonContainer>
        
    );
};

export default LineLoginButton;
