import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { ChatProvider } from './context/ChatProvider';
import ChatContainer from './components/ChatContainer';
import Signup from './components/Signup';
import EmailVerificationPage from './components/EmailVerificationPage';
import AccountPage from './components/Account';
import Homepage from './components/Homepage';
import InviteLanding from './components/InviteLanding';
import Register from './components/Register';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import i18n from './i18n';
import LoadingScreen from './components/LoadingScreen';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { Analytics } from '@vercel/analytics/react';
import { GoogleOAuthProvider } from '@react-oauth/google'
import LineAuthCallback from "./components/LineAuthCallback";
import GuestInvite from "./components/GuestInvite";
import InitChat from "./components/InitChat";
import InitChatJoin from "./components/InitChatJoin";
import SignIn from "./components/SignIn";

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color-dark-palette: #1a1a1a;
    --secondry-color-dark-palette: #373737;
    --blue-button-color: #3c95f4;
    --blue-active-color: #2070c6;
    --blue-gradient: #404258; //#3c95f4; //linear-gradient(90deg, #3c95f4 65%, #3385dc 100%);
    --yellow-gradient: #f2994a; //linear-gradient(to right, #f2994a, #f2c94c);
    --red-gradient: #c31432; //linear-gradient(to right, #c31432, #c31432);
    --orange-gradient: #f2994a; //linear-gradient(to right, #f2994a, #f2c94c);
    --green-gradient: #56ab2f; //linear-gradient(to right, #56ab2f, #a8e063);
    --light-green-gradient: #2ecc71; //linear-gradient(to right, #2ecc71, #27ae60);
    --dark-blue-gradient: #6a3093; //linear-gradient(to right, #6a3093, #a044ff);
    --dark-red-gradient: #cb2d3e; //linear-gradient(to right, #cb2d3e, #ef473a);
    --pink-gradient: #ff5858; //linear-gradient(to right, #ff5858, #f857a6);
    --black-gradient: #434343; //linear-gradient(to right, #434343, #000000);
    --blue-white-red-gradient: #0055A4; //linear-gradient(to right, #0055A4, #EF4135);
    --red-white-blue-gradient: #A71930; //linear-gradient(to right, #A71930, #A71930);
    --orange-white-gradient: #FF7900; //linear-gradient(to right, #FF7900, #FF7900);
    --blue-white-star-gradient: #0033A4; // linear-gradient(to right, #0033A4, #0033A4); 
    --blue-red-yellow-gradient: #0033A4; //linear-gradient(to right, #0033A4, #0033A4);
    --purple-gradient: #ab47bc; //linear-gradient(135deg, #ab47bc, #7b1fa2);
    --red-message-text: #FF0000;
    --white-message-text: #FFFFFF;
    --black-message-text: #000000;
  }

  * {
    margin: 0;
    padding: 0;
    outline: transparent;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background: var(--blue-gradient);
  }
`;

const Background = styled.div`
position: absolute;
height: 100vh;
width: 100%;
overflow: hidden;
z-index: -1;

&::before, &::after {
    content: '';
    position: absolute;
    inset: -170px auto auto -200px;
    width: clamp(30vw, 600px, 42vw);
    height: clamp(30vw, 600px, 42vw);
    border-radius: 50%;
    //background: #1e6dbf;
    z-index: -1;
  }

  &::after {
    inset: auto -170px -200px auto;
  }

  @media (max-width: 820px) {
    &::before, &::after {
      width: 100%;
      height: auto;
    }
  }
`;

const AboutusEn = React.lazy(() => import('./pages/AboutusEn'));
const AboutusJa = React.lazy(() => import('./pages/AboutusJa'));
const AboutusZhCN = React.lazy(() => import('./pages/AboutusZhCN')); // Mandarin
const AboutusZhHK = React.lazy(() => import('./pages/AboutusZhHK')); // Cantonese
const AboutusEs = React.lazy(() => import('./pages/AboutusEs')); // Spanish
const AboutusFil = React.lazy(() => import('./pages/AboutusFil')); // Filipino (Tagalog can be represented as "tl" or "fil")
const AboutusFr = React.lazy(() => import('./pages/AboutusFr')); // French
const AboutusHe = React.lazy(() => import('./pages/AboutusHe')); // Hebrew
const AboutusHi = React.lazy(() => import('./pages/AboutusHi')); // Hindi
const AboutusHu = React.lazy(() => import('./pages/AboutusHu')); // Hungarian
const AboutusNl = React.lazy(() => import('./pages/AboutusNl')); // Dutch
const AboutusPa = React.lazy(() => import('./pages/AboutusPa')); // Punjabi
const AboutusPt = React.lazy(() => import('./pages/AboutusPt')); // Portuguese
const AboutusRu = React.lazy(() => import('./pages/AboutusRu')); // Russian
const AboutusTh = React.lazy(() => import('./pages/AboutusTh')); // Thai
const AboutusDe = React.lazy(() => import('./pages/AboutusDe')); // German
const AboutusBn = React.lazy(() => import('./pages/AboutusBn')); // Bengali

function App() {

  const language = i18n.language;
  let AboutUsComponent;

  AboutUsComponent = AboutusEn;

  switch(language) {
    case 'ja':  // Japanese
      AboutUsComponent = AboutusJa;
      break;
    case 'zh-CN':  // Mandarin
      AboutUsComponent = AboutusZhCN;
      break;
    case 'zh-HK':  // Cantonese (using the code for Hong Kong Chinese)
      AboutUsComponent = AboutusZhHK;
      break;
    case 'es':  // Spanish
      AboutUsComponent = AboutusEs;
      break;
    case 'fil':  // Filipino (Tagalog code)
      AboutUsComponent = AboutusFil;
      break;
    case 'tl':  // Filipino (Tagalog code)
      AboutUsComponent = AboutusFil;
      break;
    case 'fr':  // French
      AboutUsComponent = AboutusFr;
      break;
    case 'he':  // Hebrew
      AboutUsComponent = AboutusHe;
      break;
    case 'hi':  // Hindi
      AboutUsComponent = AboutusHi;
      break;
    case 'hu':  // Hungarian
      AboutUsComponent = AboutusHu;
      break;
    case 'nl':  // Dutch
      AboutUsComponent = AboutusNl;
      break;
    case 'pa':  // Punjabi
      AboutUsComponent = AboutusPa;
      break;
    case 'pt':  // Portuguese
      AboutUsComponent = AboutusPt;
      break;
    case 'ru':  // Russian
      AboutUsComponent = AboutusRu;
      break;
    case 'th':  // Thai
      AboutUsComponent = AboutusTh;
      break;
    case 'de':  // German
      AboutUsComponent = AboutusDe;
      break;
    case 'bn':  // Bengali
      AboutUsComponent = AboutusBn;
      break;
    default: 
      AboutUsComponent = AboutusEn;
      break;
}

  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
      <Router>
      <GlobalStyle />
      <Background />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
          <ChatProvider>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/about-us" element={<AboutUsComponent />} />
              <Route path="/verify-email" element={<EmailVerificationPage />} />
              <Route path="/invite/:inviteId" element={<InviteLanding />} />
              <Route path="/chat" element={<ChatContainer />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/auth/line" element={<LineAuthCallback />} />
              <Route path="/guest-invite" element={<GuestInvite />} />
              <Route path="/init-chat" element={<InitChat />} />
              <Route path="/signin" element={<SignIn/>} />
              {/*<Route path="/init-chat-join" element={<InitChatJoin />} />*/}
            </Routes>
          </ChatProvider>
        </GoogleOAuthProvider>
    </Router>
    <Analytics />
    </Suspense>
    </>
  );
}

export default App;
