import React, { useState, useEffect } from 'react';
import { useChat } from '../context/ChatProvider';
import ConfirmationModal from '../styled/ConfirmationModal';
import { useTranslation } from "react-i18next";
import axios from 'axios';

const RoomInviteModal = () => {
  const { socket, rooms, setRooms, joinRoom } = useChat();
  const [inviteInfo, setInviteInfo] = useState(null);
  const [roomIdToJoin, setRoomIdToJoin] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleReceiveRoomInvite = ({ room, fromUser }) => {
        console.log("recieved 'room-invite'");
        setInviteInfo({ room, fromUser });
    };

    if (socket) {
      socket.on('receive-room-invite', handleReceiveRoomInvite);

      return () => {
        socket.off('receive-room-invite', handleReceiveRoomInvite);
      };
    }
  }, [socket]);


  const handleJoinInviteRoom = (inviteRoom) => {
    joinRoom(inviteRoom.id);
    setInviteInfo(null);
  }

  const handleAccept = () => {
    
    // 1. Grab the Room details and add it to the users rooms chatcontext ..

    let inviteRoom = rooms.find(room => room.id === inviteInfo.room.id); 

    // if room isnt in the users context, add it
    if(!inviteRoom){
        inviteRoom = inviteInfo.room;
        setRooms([...rooms, inviteRoom]);

        try{
          // update db with rooms user has been invited to
          axios.post(`${process.env.REACT_APP_SERVER}/invitedRoom`, { roomId: inviteRoom.id }, { withCredentials: true });
        }catch(error){}
        
        // useEffect hook has rest of joinRoom code as we need to wait and ensure
        // rooms collection in conext is updated with added room
        setRoomIdToJoin(inviteRoom.id);
    }else{
      // 2. do normal join room logic to bring user into invited room
      handleJoinInviteRoom(inviteRoom);
    }
  };

  
  // We want this to run when the rooms collection is updated ..
  useEffect(() => {
      if(inviteInfo?.room) {
          handleJoinInviteRoom(inviteInfo.room);
      }
  }, [rooms, roomIdToJoin]);


  const handleDecline = () => {
    setInviteInfo(null);
  };

  return (
    inviteInfo && (
        <ConfirmationModal 
            message={`${inviteInfo.fromUser} ${t('OnlineUserInviteRequest')}`} 
            onConfirm={handleAccept} 
            onClose={handleDecline}  />
    )
  );
};

export default RoomInviteModal;
