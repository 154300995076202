import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { languageConfig } from './LanguageConfig';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import i18n from '../i18n';
import { v4 as uuidv4 } from 'uuid';

const ChatContext = createContext();

export const useChat = () => {
    return useContext(ChatContext);
}

const getInitialItem = (key, defaultValue) => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch (error) {
        console.error(`Error parsing stored value for '${key}' :`, error);
        // Clear the invalid entry from sessionStorage
        sessionStorage.removeItem(key);
        return defaultValue;
      }
    }
    return null;
  };

export const ChatProvider = ({ children }) => {
    const [user, setUser] = useState(getInitialItem('user', null));
    const [currentRoom, setCurrentRoom] = useState(getInitialItem('currentRoom', null));
    const [rooms, setRooms] = useState(getInitialItem('rooms', []));
    const [socket, setSocket] = useState(null);
    const [token, setToken] = useState(getInitialItem('token', null));
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [isMessageSending, setIsMessageSending] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [whoIsTyping, setWhoIsTyping] = useState("");
    const [isNavOpen, setIsNavOpen] = useState(true); //useState(window.innerWidth <= 820 ? false : true) // handles navigation state
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 820 ? true : false); // determines if on a mobile device
    const [activeView, setActiveView] = useState("chat"); // default to 'chat' 
    const [onlineUsersCount, setOnlineUsersCount] = useState(0);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [chatInputMode, setChatInputMode] = useState([]);
    const [connectionState, setConnectionState] = useState('connected'); // can be 'connected', 'reconnecting', or 'disconnected'
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // used to control hiding/showing navigation component when user is typing message
    const logoutTimeoutRef = useRef(null); // timeout counter to log user out automatically if connection to server is lost and cant be re-established
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [blockSending, setBlockSending] = useState(false);
    const [initJoin, setInitJoin] = useState(null);
    
    const navigate = useNavigate(); 

    const handleLogout = (socket) => {

        try{
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; httpOnly;";
            localStorage.clear();
            sessionStorage.clear();

            if(socket){
                socket.io.opts.reconnection = false;
                socket.disconnect(true);
            }

            setSocket(null);

            if (logoutTimeoutRef.current) {
                clearTimeout(logoutTimeoutRef.current);
            }
            
        }catch(error){
            console.error('Error during logout cleanup:', error);
        }finally{
            resetUserState();
            navigate("/");
        }
    };

    const resetUserState = () => {
        setUser(null);
        setCurrentRoom(null);
        setRooms([]);
        setOnlineUsers([]);
        setOnlineUsersCount(0);
        setToken(null);
        setActiveView("chat");
        setIsNavOpen(true);
        setIsTextareaFocused(false);
        setBlockSending(false);
        setInitJoin(null);
    };
    
    const resetContextFor = (user) => {   
        setUser(user);
        setCurrentRoom(user.latestRoom);
        setActiveView("chat");
        setIsNavOpen(false);
        setIsTextareaFocused(false);
        setBlockSending(false);
    };

    const fetchRooms = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/rooms`, { withCredentials: true });
            setRooms(response.data);
            //console.log("setting rooms in sessionState", response.data);
            sessionStorage.setItem('rooms', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.error('Error fetching rooms from server :', error);
        }
    };

    const addLanguageToRoom = (roomId, language) => {
        setRooms(prevRooms => 
            prevRooms.map(room => {
                if (room.id === roomId) {
                    // Adding user's language to the room's languages array if it doesn't already exist
                    const newLanguages = room.languages.includes(language) ? room.languages : [...room.languages, language];
                    return { ...room, languages: newLanguages };
                } else {
                    return room;
                }
            })
        );
    }
    
    const addUserToRoom = (roomId, user) => {
        //console.log('Adding user to room.users and room.languages collection');
        
        setRooms(prevRooms => 
            prevRooms.map(room => {
                if (room.id === roomId) {
                    // Adding user's language to the room's languages array if it doesn't already exist
                    const newLanguages = room.languages.includes(user.language) ? room.languages : [...room.languages, user.language];
                    return { ...room, users: [...room.users, user], languages: newLanguages };
                } else {
                    return room;
                }
            })
        );
    };
    
    const removeUserFromRoom = (roomId, user) => {
        //console.log('Removing user from room.users collection');
        
        setRooms(prevRooms => 
            prevRooms.map(room => {
                if (room.id === roomId) {
                    const userIndex = room.users.findIndex(u => u.id === user.id);
                    if (userIndex !== -1) {
                        return { ...room, users: room.users.filter((_, index) => index !== userIndex) };
                    }
                }
                return room;
            })
        );
    };

    const getRoomLanguages = (roomID) => {
        // Find the room by its ID
        const room = rooms?.find(room => room.id === roomID);
        
        if(!room)
            return [];
        
        return [...room.languages];
    }
    
    const getRoomUsers = (roomID) => {
        const room = rooms?.find(room => room.id === roomID);
        
        if(!room) return [];
        
        return [...room.users];
    }

    const leaveRoom = (roomID) => {
        //console.log(`Emitting leave-room ${roomID}`);
        const roomLanguages = getRoomLanguages(roomID);
        socket.emit('leave-room', { currentUser:null, roomID, roomLanguages });  // passing null for user here is on purpose so server event handler will grab it from socket.user
        setCurrentRoom(null);
        sessionStorage.setItem('currentRoom', null);
    }

    // Primary join room logic contained here ..
    // Joining what room, with what language
    const joinRoom = async (roomID, useRoom = null) => {
        
        // if they are already in the room, do nothing
        if(currentRoom?.id === roomID) {
            setIsNavOpen(false);
            setActiveView("chat");
            return;
        }

        setLoadingMessages(true);

        let selectedRoom;
        // check if we should re-fetch rooms
        if(!rooms && useRoom){
            //await fetchRooms;
            selectedRoom = useRoom;
        }else{
            selectedRoom = rooms.find(room => room.id === roomID);
        }
        console.log(selectedRoom);
        
        setCurrentRoom(selectedRoom);
        // setting this so it can be checked on reconnect to auto join room user was in when disconnected ..
        //localStorage.setItem('currentRoom', selectedRoom.id);
        sessionStorage.setItem('currentRoomId', selectedRoom.id);
        sessionStorage.setItem('currentRoom', JSON.stringify(selectedRoom));
        
        let roomLanguages;
        if(!rooms && useRoom){
            roomLanguages = useRoom.languages;
        }else{
            roomLanguages = getRoomLanguages(roomID);
        }
        
        socket.emit('join-room', { roomID, roomLanguages, userJoining:user } );

        setActiveView("chat"); 
        setIsNavOpen(false);
    }

    const value = {
        user,
        socket,
        token,
        currentRoom,  // do we need this? really just need the ID for the room ... 
        rooms,
        supportedLanguages,
        isMessageSending,
        isTyping,
        whoIsTyping,
        isNavOpen, 
        activeView,
        onlineUsersCount,
        onlineUsers,
        connectionState,
        chatInputMode,
        isTextareaFocused,
        isMobile,
        loadingMessages,
        blockSending,
        initJoin,
        addLanguageToRoom,
        handleLogout,
        fetchRooms,
        resetContextFor,
        setUser,
        setSocket,
        setToken,
        setCurrentRoom,
        setRooms,
        setSupportedLanguages,
        setIsMessageSending,
        setIsTyping,
        setWhoIsTyping,
        setIsNavOpen,
        setActiveView,
        joinRoom,
        leaveRoom,
        getRoomLanguages,
        getRoomUsers,
        setIsTextareaFocused,
        setIsMobile,
        setLoadingMessages,
        setBlockSending,
        setInitJoin
    };

    // Temp code to set language to test translations ..
    useEffect(() => {

        //loadSessionState();

        function handleLanguageChange(lng) {
            document.cookie = `i18next=${lng};path=/;`;
            localStorage.setItem('i18nextLng', lng);
        }

        i18n.on('languageChanged', handleLanguageChange);
        
        // explicitly set the language .. 
        // this should be based on Users language, and called in the other useEffect that had a dependency on User

        //i18n.changeLanguage('ja');

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, []);

    // load chat rooms from db
    useEffect(() => {
        
        // only do api calls for rooms if user is authenticated/loggedin
        if(user)
        {
            // set language based on users setting
            //if(!user.guest){
                
                // i18n.changeLanguage(languageConfig[user.language].loc);
                //
                // // for keyboard layout on chat form
                // setChatInputMode(languageConfig[user.language].input);
                
                // set this explicitly if found, otherwise will just default to english (for UI elements)
                if(languageConfig[user.language]){
                    i18n.changeLanguage(languageConfig[user.language].loc);
                    // for keyboard layout on chat form
                    setChatInputMode(languageConfig[user.language].input);
                }
            //}
            
            // determine if user should be blocked from sending (i.e. reached their quota, not a subscriber)
            if(!user.subscriber){
                setBlockSending((user.messagesSent >= user.messageQuota));
            }
            
            fetchRooms();
        }

        setSupportedLanguages(Object.keys(languageConfig).sort());
        
    }, [user]);

    
    useEffect(() => {

        if (user && token) {

            console.log("Attempting socket.io connection ..");
            if(socket?.connected) return;

            //console.log("no connection found, establishing new socket ..");

            // session token used to prevent multiple logins, particularly on reconnect
            const sessionId = sessionStorage.getItem('sessionId') || uuidv4();
            sessionStorage.setItem('sessionId', sessionId);

            // include the currentRoom here as well, in case of browser refresh and new connection attempt ...
            
            // Initialize socket connection if user and token are present
            const socketInstance = io.connect(process.env.REACT_APP_SERVER || window.location.host, {
                query: {
                    token: `Bearer ${token}`,
                    sessionId: sessionId,
                    currentRoomId: sessionStorage.getItem('currentRoomId')
                },
                transports: ['websocket'],
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 15,
            });
            
            setSocket(socketInstance);

            console.log("Socket.io connection established.");
        }
    
    }, [user, token]);


    useEffect(() => {

        if(socket){

            socket.on('connect', () => {
                setConnectionState('connected');
                //console.log(connectionState);

                // Clear the logout timeout if a connection is re-established
                if (logoutTimeoutRef.current) {
                    clearTimeout(logoutTimeoutRef.current);
                }
            });

            socket.on('reconnect_attempt', () => {
                setConnectionState('reconnecting');
                //console.log('Attempting to reconnect ...');
                //console.log(connectionState);

                // Clear the logout timeout on reconnect attempts
                if (logoutTimeoutRef.current) {
                    clearTimeout(logoutTimeoutRef.current);
                }
            });

            socket.on('reconnect_failed', () => {
                setConnectionState('disconnected');
                //console.log('Reconnect failed ... logging out.');

                // Don't call handleLogout here to avoid double logout calls
                // Instead, clean up the client state directly
                resetUserState();
                navigate("/");
            });

            socket.on('disconnect', (reason) => {
                setConnectionState('disconnected');
                //console.log(connectionState);

                // Start a timeout that logs out the user after 30 seconds of disconnection
                logoutTimeoutRef.current = setTimeout(() => {
                    handleLogout();
                    // resetUserState();
                    // navigate("/");
                }, 30000); // 30 seconds

                // Ensure we pass current room on reconnect attempts so server can rejoin room automatically
                const newCurrentRoomId = sessionStorage.getItem('currentRoomId');
                // Update the query parameters with the new currentRoomId
                socket.io.opts.query = {
                    ...socket.io.opts.query,
                    currentRoomId: newCurrentRoomId,
                };

                if (reason === 'io server disconnect') {
                    //console.log('Disconnected from server.');
                    //socket.connect();
                }
            })

            socket.on('room-users-added', (roomId, user) => {
                //console.log('room-users-added called');
                addUserToRoom(roomId, user);
            });

            socket.on('room-users-removed', (roomId, user) => {
                //console.log('room-users-removed called');
                removeUserFromRoom(roomId, user);
            });

            socket.on('onlineUsersList', (data) => {
                setOnlineUsers(data.onlineUsers);
                setOnlineUsersCount(data.onlineUsers.length);
                sessionStorage.setItem('onlineUsers', JSON.stringify(data.onlineUsers));
            });

            socket.on('clear-unreadmessagecount', (roomId) => {
                setRooms(prevRooms => {
                    return prevRooms.map(room => {
                        if (room.id === roomId) {
                            return {
                                ...room,
                                unreadMessagesCount: 0,
                            };
                        }
                        return room;
                    });
                });
            });

            // Sent/received when a room is deleted by owner
            socket.on('room-deleted', (roomId) => {
                // if user is currently in the room, remove them
                //let updatedRooms = (prevRooms) => prevRooms.filter((room) => room.id !== roomId);
                setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId)); 
                leaveRoom(roomId);
            });

            let typingTimeoutId = null;
            socket.on('user-typing', (data) => {
                if (data.userID !== user.id) {
                    setIsTyping(true);
                    setWhoIsTyping(data.username);

                    // Clear the existing timeout, if any
                    if (typingTimeoutId) {
                        clearTimeout(typingTimeoutId);
                    }
                    
                    // Optionally, set a timeout to hide the typing message after a few seconds
                    setTimeout(() => {
                        setIsTyping(false);
                        setWhoIsTyping(data.username);
                    }, 3000);
                }
            });

            // Attempt to reconnect the socket when the user returns to the app
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {

                    if(socket?.connected) return;

                    //console.log("visibilty change - reconnecting ..");
                    //socket.connect();
                }
            });

            return () => { 
                socket.off('reconnect_failed');
                socket.off('disconnect');
                socket.off('connect');
                socket.off('reconnect_attempt');
                socket.off('room-users-updated');
                socket.off('onlineUsersList');
                socket.off('clear-unreadmessagecount');
                socket.off('room-deleted');
                socket.off('reconnected');

                // Unregister other socket event handlers...
                document.removeEventListener('visibilitychange', () => {
                    if (document.visibilityState === 'visible') {
                        //socket.connect();
                    }
                });
            };
        }

    }, [user, socket]);
    

    return (
        <ChatContext.Provider value={ value }>
            { children }
        </ChatContext.Provider>
    );
};