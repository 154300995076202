import React, { useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import ModalAlert from "./ModalAlert";
import { useTranslation } from "react-i18next";

const FeedbackContainer = styled.div`
    margin-top: 2em;
    //padding: 1em;
    //background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(243, 243, 243);
    padding: 20px;

    & p {
        margin-bottom: 1em;
    }
`;

const FeedbackHeader = styled.h2`
    font-size: 1em;
    margin-bottom: 1em;
`;

const FeedbackTextarea = styled.textarea`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 100px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const FeedbackButton = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '', isSuccess:false });
  const { t } = useTranslation();

  const handleFeedbackSubmit = async () => {
    try {
        if(!feedback) return;
        
        await axios.post(`${process.env.REACT_APP_SERVER}/feedback`, { feedback }, { withCredentials: true });
        
        setModalContent({ title: `${t('SuccessTitle')}`, message: `${t('FeedbackUpdatedSuccess')}`, isSuccess: true });
        setFeedback("");
    } catch (error) {
        setModalContent({ title: 'Failed', message: 'Feedback submission failed. Please try again.', isSuccess: true });
    }

    setIsModalOpen(true);
  };

  return (
    <>
    <FeedbackContainer>
      <FeedbackHeader>{t('AccountFeedbackTitle')}</FeedbackHeader>
      <p>{t('AccountFeedbackText')}</p>
      <FeedbackTextarea 
        value={feedback} 
        onChange={(e) => setFeedback(e.target.value)} 
        maxLength="500"
      />
      <FeedbackButton onClick={handleFeedbackSubmit}>
      {t('SubmitFeedbackButton')}
      </FeedbackButton>
    </FeedbackContainer>

    <ModalAlert 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        title={modalContent.title} 
        message={modalContent.message}
        isSuccess={modalContent.isSuccess} 
    />
    </>
  );
};

export default FeedbackForm;
