import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useChat} from "../context/ChatProvider";
import LoadingScreen from "./LoadingScreen";
import axios from 'axios';

const GuestInvite = () => {
    const { joinRoom, socket } = useChat();
    const navigate = useNavigate();
    
    const authGuest = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}/guest-invite-auth`, { withCredentials: true });

        const { user } = response.data;
        
        if(user){
            joinRoom(user.guestRoomId);
            navigate('/chat');
        }
    }
    
    useEffect(() => {
        authGuest();
    },[socket]);

    return(<LoadingScreen />);
};

export default GuestInvite;