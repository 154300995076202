import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useChat} from "../context/ChatProvider";
import LoadingScreen from "./LoadingScreen";

const LineAuthCallback = () => {
    const navigate = useNavigate();
    const { setUser, setToken } = useChat();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        const state = params.get("state");
        const savedState = sessionStorage.getItem('line_auth_state');
        
        //if (code && state && state === savedState) {
        if (code && state) {
            
            handleLineLogin(code);

            // state contains ?invite=lsjdfljlsdjf 
            // (this is attached to QR code, and passed through as state if login required)
            let inviteId = extractInviteId(state);
            if(inviteId.length > 3){
                navigate(`/invite/${inviteId}`);
            }else{
                // default navigate
                navigate('/chat');
            }
        
        } else {
            // Redirect to homepage or show error
            console.log("No code or state found");
            navigate('/');
        }
    }, [navigate]);

    const extractInviteId = (state) => {
        const inviteIdKey = '?inviteId=';
        const startIndex = state.indexOf(inviteIdKey);

        if (startIndex === -1) {
            // inviteId not found
            return null;
        }

        const inviteIdStart = startIndex + inviteIdKey.length;
        const inviteId = state.substring(inviteIdStart);

        return inviteId;
    };

    const handleLineLogin = async (code) => {
        try {
            // Call your backend endpoint with the LINE code
            const backendResponse = await axios.post(`${process.env.REACT_APP_SERVER}/auth/line`, { code }, { withCredentials: true });

            const { token, user } = backendResponse.data;

            if (token && user) {
                setToken(token);
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', JSON.stringify(token));
                sessionStorage.setItem('userLoggedInWithGoogle', "FALSE");
                
                // NOTE : socket.io connection is made in ChatProvider once User and Token is set
                
            } else {
                // Handle the case where the ID token is not verified
                console.error("Invalid token / Unable to authenticate");
                navigate('/');
            }

        } catch (error) {
            console.log(error);
            navigate('/');
        }
    };

    return (
        <LoadingScreen /> // You can show a loading indicator or any appropriate content
    );
};

export default LineAuthCallback;
