import styled from "styled-components";

export const PasswordInputContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const PasswordToggleIcon = styled.span`
    position: absolute;
    top: 60%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    color:lightgray;
    font-size: 1.5rem;
`;

export const PasswordInputContainerNoCover = styled.div`
    position: relative;
    width: 95%;
`;

export const PasswordToggleIconNoCover = styled.span`
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
    color:var(--blue-gradient);
    font-size: 1.8rem;
`;