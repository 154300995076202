import React from "react";
import styled, { keyframes } from 'styled-components';
import TopBarNav from '../styled/Topbar';

const PageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
`;

const PageContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: left;
    justify-content: left;
    background: #fff;
    padding: 35px 25px;
    border-radius: 10px;
    border: 1px solid #ddd;
    position: relative;
    width: 100%;        // Takes up full width of its parent
    max-width: 50em;    // But will not exceed 50em
    margin-top:3em;

    img {
        align-items: center;
    }

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 40px;
        border: none;
        border-radius: 5px;
        background: var(--blue-active-color);
        transition: .3s ease-in-out opacity, box-shadow;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
    }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const PageTemplate = ({ children }) => {
  
  return (
    <PageContainer>
        <TopBarNav />
        <PageContentContainer>
        
            { children }

        </PageContentContainer>
    </PageContainer>
  );
}

export default PageTemplate;
