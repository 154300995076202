import { useEffect, useState } from "react";
import { useChat } from "../context/ChatProvider";

const useMessages = () => {
    const { socket, user, setIsMessageSending, setLoadingMessages, setIsTyping } = useChat();
    const [messages, setMessages] = useState([]);
    
    useEffect(() => {

        const updateMessages = async (newMessage) => {
            
            try {
                let displayText = '';

                if(newMessage.author !== 'BOT'){
                    displayText = newMessage.translations[user.language];
                }else{
                    //removeTemporaryTypingMessage(newMessage.author);
                    //displayText = newMessage.translations["English"];
                    
                    displayText = newMessage.translations[user.language];

                    // if not translation was found for users language, just default to English one (which should always be there ..)
                    if (!displayText || displayText === '') {
                        displayText = newMessage.translations['English'];
                    }
                }
                
                //setMessages((m) => [...m, { ...newMessage, text: displayText }]);
                // removing any 'is typing' messages from this user
                //setMessages((m) => [...m.filter(message => message.id !== 'typing-' + newMessage.author), { ...newMessage, text: displayText }]);
                setMessages(m => [
                    ...m.filter(message =>
                        message.id !== 'typing-' + newMessage.author &&
                        message.id !== 'temp-' + newMessage.author
                    ),
                    { ...newMessage, text: displayText }
                ]);
                
                
                setIsMessageSending(false);
                setIsTyping(false); // incase this indicator is still going, remove it when message comes in

                window.scrollTo(0, document.body.scrollHeight);
                
            } catch (err) {
                console.error('Error updating messages collection :', err);
            }
        };

        const loadSavedMessages = (savedMessages) => {
            const relevantMessages = savedMessages.reduce((acc, msg) => {
                //let isRelevant = msg.author === 'BOT' || msg.translations[user.language];
                let isRelevant = msg.translations[user.language];

                if (isRelevant) {
                    //let displayText = msg.author !== 'BOT' ? msg.translations[user.language] : msg.translations["English"];
                    let displayText = msg.translations[user.language];
                    
                    acc.push({ ...msg, text: displayText });
                }
        
                return acc;
            }, []);
            
            setMessages(relevantMessages.reverse());

            setLoadingMessages(false);
        };

        const typingMessageFormat = (typing) => ({
            id: 'typing-' + typing.username, // Unique ID for each user's typing message
            author: typing.username,
            text: `...`,
            type: 'typing',
            translations: [],
            author_avatar: typing.avatar,
            author_language: typing.language
            // other properties can be empty or placeholders
        });

        const addTemporaryTypingMessage = (typing) => {
            // console.log(typing);
            // let isTypingMessage = typingMessageFormat(typing);
            // console.log(isTypingMessage);
            
            setMessages(currentMessages => {
                // Check if a typing message for this user already exists
                if (!currentMessages.some(message => message.id === 'typing-' + typing.username)) {
                    return [...currentMessages, typingMessageFormat(typing)];
                } else {
                    return currentMessages;
                }
            });

            window.scrollTo(0, document.body.scrollHeight);
        };
        
        const addDummyMessage = (dummyMessage) => {
            //console.log('Adding dummy message : ', dummyMessage);
            setMessages(currentMessages => {
                // Check if a typing message for this user already exists
                if (!currentMessages.some(message => message.id === 'temp-' + user.username)) {
                    return [...currentMessages, dummyMessage];
                } else {
                    return currentMessages;
                }
            });

            window.scrollTo(0, document.body.scrollHeight);
        };

        if(socket){
            socket.on('receive-message', updateMessages);
            socket.on('load-saved-messages', loadSavedMessages);

            socket.on('user-typing', (data) => {
                if (data.userID !== user.id) {
                    addTemporaryTypingMessage(data);
                }
            });
            
            socket.on('dummy-message', (dummyMessage) => {
                if(dummyMessage.id === 'temp-' + user.username){
                    addDummyMessage(dummyMessage);
                }
            });
    
            return () => {
                socket.off('receive-message');
                socket.off('load-saved-messages');
                socket.off('user-typing');
            }
        }
        
    }, [socket, user]);

    return { messages, setMessages };
}

export default useMessages;
