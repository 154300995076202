import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IoIosRadio } from "react-icons/io";
import { AiFillSound } from "react-icons/ai";
import axios from 'axios';
import { ClipLoader, BeatLoader, PuffLoader } from 'react-spinners';
import {useChat} from "../context/ChatProvider";

const MessageContentInternal = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    font-size: ${props => props.isTranslation ? '0.7em' : '0.8em'};
    font-weight: 300;
    padding: 0.8em 1em;
    height: fit-content;
    white-space: pre-line;
    
    color: ${props => props.textColor};
    //color: ${props => props.incomingMessage || props.isTranslation ? props.textColor : '#000' };
  
    //background: ${props => props.incomingMessage || props.isTranslation ? `var(--${props.messageColor})` : '#fff' };
    //background: ${props => props.incomingMessage || props.isTranslation ? props.messageColor : '#fff' };  
    background: ${props => props.messageColor };
  
    border:none;
  
    //box-shadow:  ${props => props.incomingMessage && !props.isTranslation ? 'rgba(32, 112, 198, 0.4)' : 'rgba(0, 0, 0, 0.15)'} 2px 3px 15px;
    box-shadow: ${props => props.isTranslation ? 'black 0px 3px 15px' : 'black 2px 3px 15px'}; 
    
    margin-left: ${props => (props.isTranslation && props.incomingMessage) ? '3px' :
          (!props.isTranslation && !props.incomingMessage) ? '3px': 0};
         
    margin-right: ${props => (!props.isTranslation && props.incomingMessage) ? '3px' :
          (props.isTranslation && !props.incomingMessage) ? '3px' : 0};
  
    z-index: ${props => (props.isTranslation && props.isLast) ? '' :
                        (props.isTranslation) ? 0 : 1};
  
    //border-radius: ${props => !props.isTranslation ? '0 2px 8px' : '0px 0px 0px 0px' };
    border-radius: ${
              // props =>
              //         props.isLast ? '8px 8px 0px 0px' : 
              //                 props.isTranslation ? '0px 0px 0px 0px' :
              //                   props.clickedMessage ? '0 0px 8px' : '8px 8px 8px 0px'
              props =>
                      // (props.isTranslation && props.isLast) && !props.incomingMessage ? '8px 8px 8px 0px' : 
                      //         (props.isTranslation && props.isLast) && props.incomingMessage ? '8px 0px 0px 8px' :    
                      // (props.isTranslation && !props.incomingMessage) ? '0px 8px 8px 0px' : // Rounded corners for incoming messages
                      //         !props.incomingMessage ? '0px 8px 8px 0px' : '8px 0px 0px 8px'

                      (!props.isTranslation && props.incomingMessage) ? '30px 8px 8px 0px' :
                              (!props.isTranslation && !props.incomingMessage) ? '8px 30px 0px 8px' :

                                      ((props.isFirst && props.isLast) && props.incomingMessage) ? '20px 8px 8px 20px' :
                                              ((props.isFirst && props.isLast) && !props.incomingMessage) ? '8px 20px 20px 8px' :
                                      
                              (props.isLast && props.incomingMessage) ? '20px 8px 8px 0px' :
                                      (props.isLast && !props.incomingMessage) ? '8px 20px 0px 8px' :

                                              (props.isFirst && props.incomingMessage) ? '0px 8px 8px 20px' :
                                                      (props.isFirst && !props.incomingMessage) ? '8px 0px 20px 8px' :
                                                              props.incomingMessage ? '0px 8px 8px 0px' :
                                                                      '8px 0px 0px 8px'
      
                      // props.messageType === 'typing' ? '8px 8px 8px 0px' :
                      // (props.isTranslation && props.isLast) && props.incomingMessage ? '8px 8px 8px 0px' :
                      //         (props.isTranslation && props.isLast) && !props.incomingMessage ? '8px 8px 0px 8px' :
                      //                 (props.isTranslation && props.incomingMessage) ? '0px 8px 8px 0px' : // Rounded corners for incoming messages
                      //                         props.incomingMessage ? '2px 8px 8px 0px' : '8px 2px 0px 8px'
      };
  
`;

const PlayButton = styled.button`
    // Style for the play button (e.g., size, color)
      cursor: pointer;
      border: none;
      padding: 0 20px 0 15px;
      display: flex;
      align-items: center;
      //color: white;
      //color: ${props => props.audioLoaded ? 'white' : 'dimgray'};
      color: ${props => props.textColor};
      background: ${props => props.messageColor };
      width:20px;
      height: 30px;
`;

const Icon = styled.div`
    font-size: 20px; // Increase the size as needed
    margin-top:5px;
  
`;

// const DummyPlayButton = styled.span``;


const MessageContent = ({ messageKey, text, messageColor, textColor, incomingMessage, isTranslation, onClick, voiceId, showAudio, clickedMessage, isLast, isFirst, messageType, onPlayAudio }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadedAudioUrl, setLoadedAudioUrl] = useState("");
    const audioContext = useRef(null);
    const { socket } = useChat();

    const createAudioContext = () => {
        if (!audioContext.current) {
            audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        }
    };

    // Convert base64 to Blob
    const base64ToBlob = (base64, contentType) => {
        const binaryData = atob(base64);
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            bytes[i] = binaryData.charCodeAt(i);
        }
        return new Blob([bytes], { type: contentType });
    };
    
    // OBSOLETE: We are not currently making use of websockets for tts audio
    useEffect( () => {
        
        const endAudio = (data) => {
            const parsedMessage = JSON.parse(data);
            if (parsedMessage.status === 'finished') {
                console.log("tts finished.");
                //setPlayingAudio(null);
                setIsPlaying(false);
            }
        }

        
        let audioQueue = [];
        let currentAudio = null;
        let nextAudio = null;
        let isPlaying = false;

        const playNextAudioChunk = async () => {
            if (audioQueue.length === 0) {
                isPlaying = false;
                //console.log('ending-tts for ', currentlyPlaying);
                //socket.emit('ending-tts', currentlyPlaying);
                setIsPlaying(false);
                return;
            }

            setIsPlaying(true);
            isPlaying = true;
            
            // this sometimes throws exceptoins with no source found ...
            try{
                const audioUrl = audioQueue.shift();
                currentAudio = new Audio(audioUrl);
                await currentAudio.play();
                currentAudio.onended = () => {
                    playNextAudioChunk();
                    //currentAudio = null; // Clear the current audio
                };

                // Preload the next audio chunk
                if (audioQueue.length > 0) {
                    const nextAudioUrl = audioQueue[0]; // Peek at the next URL in the queue
                    nextAudio = new Audio(nextAudioUrl);
                    nextAudio.load(); // Start loading the next audio
                }
            }catch(error){
                console.log(error);
            }
        };

        const queueAudioChunk = async (audioData) => {
            //const { audio, status } = audioData.data;
            const parsedMessage = JSON.parse(audioData);

            console.log("playTTSAudio called ..");
            //console.log("audio : ", parsedMessage.audio);

            if(parsedMessage.audio){
                const audioBase64 = parsedMessage.audio;
                const audioBlob = base64ToBlob(audioBase64, 'audio/mpeg');
                const audioUrl = URL.createObjectURL(audioBlob);
                audioQueue.push(audioUrl);
                
                if (!isPlaying) {
                    await playNextAudioChunk();
                }

            }else{
                //console.log("no tts audio.");
            }
        };

        if(socket){
            socket.on('receive-tts', queueAudioChunk);
            socket.on('tts-ended', endAudio)
        }

        return () => {
            socket.off('receive-tts');
            socket.off('tts-ended');
        }

    }, [socket]);
    
    
    const getAudioUrl = async(messageKey) => {
        // check if has been generated
        const response = await axios.get(`${process.env.REACT_APP_SERVER}/tts/${messageKey}/audio`, { withCredentials: true });
        if (response.data.success) {
            return response.data.audioUrl;
        }
        
        return null;
    };
    
    // Sends request to babbleon servers to generate tts.
    // Takes response and plays it back to user
    const playAudio = async (text) => {

        setIsLoading(true);
        
        createAudioContext(); // Create audio context on user interaction
        
        // check if we have the audio url generated already and loaded into state
        if(loadedAudioUrl){
            //console.log('Playing from saved audio ..');
            setIsPlaying(true);
            await PlayLoadedAudio();
            return;
        }else {
            // preload the audio url if we aleady have it generated
            let audioUrl = await getAudioUrl(messageKey);
            if(audioUrl){
                setLoadedAudioUrl(audioUrl);
                await playThis(audioUrl);
                return;
            }
        }
        
        try {
            
            // setup and play muted preloaded audio sample ... this will then be swapped out once
            // the real requested audio is retrieved
            //https://babbleon-media.s3.us-west-2.amazonaws.com/0bf40cef-1ddc-455e-ae68-2602269702f2-Japanese.mp3
            //await playThis("https://babbleon-media.s3.us-west-2.amazonaws.com/004c45f8-7182-405f-aa18-7cbafcd23b21-French.mp3", true);
            
            //console.log('TTS : Calling ElevenLabs with ', text);
            const payload = {
                text: text,
                //voiceId: voiceId ? voiceId : process.env.DEFAULT_VOICEID, // 
                voiceId: voiceId ? voiceId : '805ddwTMyfKtslMREf79',
                messageKey: messageKey
            };
            //console.log(payload);
            
            // kick off tts generation + returns stream of audio from 11labs to play
            const response = await fetch(`${process.env.REACT_APP_SERVER}/generate-tts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'audio/mpeg'
                },
                body: JSON.stringify(payload),
                credentials: 'include' // if you need credentials similar to axios's withCredentials
            });
            
            setIsLoading(false);
            setIsPlaying(true);

            if (response.ok && response.body) {
                const audioBlob = await response.blob(); // Get the blob directly from the response
                const audioUrl = URL.createObjectURL(audioBlob);

                const audio = new Audio(audioUrl);
                audio.onended = () => {
                    setIsPlaying(false);
                };
                await audio.play();
            } else {
                console.error('Failed to fetch audio data');
            }
            
            // we also save the generated tts audio file for replays - this polling
            // checks and updates a local var when the file is available, generally by the
            // first attempted replay
            await startPolling2();
            
            
        } catch (error) {
            console.error('Error generating audio:', error);
            setIsPlaying(false);
        }
    };

    const startPolling2 = async () => {
        return new Promise((resolve, reject) => {
            let intervalId = setInterval(async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_SERVER}/tts/${messageKey}/audio`, { withCredentials: true });
                    if (response.data.success) {
                        clearInterval(intervalId);
                        setLoadedAudioUrl(response.data.audioUrl);
                        
                        // await playThis(response.data.audioUrl);
                        // setIsPlaying(false);
                        
                        resolve(response.data.audioUrl);  // Resolve the promise with the URL
                    }
                } catch (error) {
                    console.error('Error fetching and playing tts:', error);
                    clearInterval(intervalId);
                    reject(error);  // Reject the promise on error
                }
            }, 2000);
        });
    };

    const startPolling = async () => {
        
        let intervalId = setInterval(async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/tts/${messageKey}/audio`, { withCredentials: true });

                if (response.data.success) {

                    setIsLoading(false);
                    //setIsPlaying(true);
                    
                    //console.log("Audio loaded - ready for playback.", response.data.audioUrl);
                    setLoadedAudioUrl(response.data.audioUrl);
                    
                    clearInterval(intervalId);
                    
                    await playThis(response.data.audioUrl);
                    
                    // const audio = new Audio(response.data.audioUrl);
                    // audio.onended = () => setIsPlaying(false);
                    // await audio.play();
                    
                    // axios.get(response.data.audioUrl, { responseType: 'arraybuffer', withCredentials: true })
                    //     .then(response => audioContext.decodeAudioData(response.data))
                    //     .then(audioBuffer => {
                    //         const source = audioContext.createBufferSource();
                    //         source.buffer = audioBuffer;
                    //         source.connect(audioContext.destination);
                    //         source.start(0);
                    //         source.onended = () => setIsPlaying(false);
                    //     })
                    //     .catch(error => {
                    //         console.error('Error with Web Audio API:', error);
                    //         setIsPlaying(false);
                    //     });

                    //clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error fetching and playing tts:', error);
                clearInterval(intervalId);
                setIsLoading(false);
                setIsPlaying(false);
            }
        }, 2000);

        return () => clearInterval(intervalId);
    };
    
    const playThis = async (url, muted = false) => {
        if (audioContext.current && url) {
            
            //console.log('PlayThis called ..', url);
            setIsLoading(false);
            setIsPlaying(true);
            
            const audio = new Audio(url);
            audio.crossOrigin = "anonymous";
            audio.muted = muted;
            audio.src = url;
            
            const track = audioContext.current.createMediaElementSource(audio);
            track.connect(audioContext.current.destination);
            audio.onended = () => {
                // as we kick this off initially to play muted sound while loading TTS, we don't want it 
                // to stop playing icon .. this will be done for the real audio played (the generated TTS)
                if(!muted)
                    setIsPlaying(false);
                //audioContext.current.close(); // Close the audio context when done
                //audioContext.current = null;
            };
            await audio.play().then(() => {
              //audio.muted = muted;  
            }).catch(e => {
                console.error("Error playing audio:", e);
                setIsPlaying(false);
            });
        }else{
            //console.log("PlayThis : no audio context ...");
            //console.log(audioContext);
            //console.log(url);
        }
    };
    
    const PlayLoadedAudio = async() => {
        
        //console.log("Play Loaded Audio called ...");
        
        try{
            if(!loadedAudioUrl){
                //console.log("No audio url set.");
                setIsPlaying(false);
                return;
            }
            
            const audio = new Audio(loadedAudioUrl);
            audio.onended = () => {
                setIsPlaying(false);
                setIsLoading(false);
                //setLoadedAudioUrl("");
            }
            await audio.play();
            
        }catch(error){
            alert(error);
           console.log(error);
           setIsPlaying(false);
           setIsPlaying(false);
        }
    }

    // useEffect(() => {
    //     if (loadedAudioUrl) {
    //         spanRef.current.click();
    //     }
    // }, [loadedAudioUrl]);
    
    // const handlePermissions = async () => {
    //     let permissions = { audio: true };
    //     try {
    //         await navigator.mediaDevices.getUserMedia(permissions);
    //     }catch (err) {
    //         console.error(err.message);
    //         permissions = { audio: false };
    //     }
    //     //if (!permissions.video) console.error("Failed to get video permissions.");
    //     if (!permissions.audio) console.error("Failed to get audio permissions.");
    // };

    return (
        <MessageContentInternal 
            incomingMessage={incomingMessage} 
            messageColor={messageColor} 
            textColor={textColor}
            isTranslation={isTranslation}
            onClick={onClick}
            clickedMessage={clickedMessage}
            isLast={isLast}
            isFirst={isFirst}
            messageType={messageType}
        >
            { text === '...' ? <BeatLoader size={10} color={textColor} /> : text }
            {showAudio &&
                <PlayButton
                    onClickCapture={() => playAudio(text)}
                    disabled={isPlaying}
                    textColor={textColor}
                    messageColor={messageColor}
                    audioLoaded={loadedAudioUrl}
                >
                    
                        {/*{isLoading && <ClipLoader size='15' color='#ffffff' /> }*/}
                        {/*{isPlaying ? <IoIosRadio /> : <IoIosPlayCircle />}*/}

                        {(isLoading || isPlaying) ? <PuffLoader size='20px' color={textColor} /> : <Icon><AiFillSound /></Icon>}
                        
                    
                    {/*<DummyPlayButton ref={spanRef} onClickCapture={() => PlayLoadedAudio()}></DummyPlayButton>*/}
                </PlayButton>
            }
        </MessageContentInternal>
    );
};

export { MessageContent }