import styled from 'styled-components';
import { MdDelete } from 'react-icons/md';

export const DeleteButton = styled(MdDelete)`
    width: 34px;     // Explicit width
    height: 24px;    // Explicit height
    flex-shrink: 0;  // Prevent it from shrinking in flexbox
    //color: #aaa;       // Initial color
    margin-left: auto; // Push to the right-most side
    transition: all 0.3s ease; // Smooth transition for hover effects
  //background: var(--secondry-color-dark-palette);
  color: white;
  border-radius: 5px;
  
    &:hover {
        color: #ff4d4d;      // Color upon hover to indicate delete
        transform: scale(1.1);  // Slight growth in size to indicate interactivity
    }
`;

