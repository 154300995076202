export const languageConfig = {
    'Mandarin': {
        messageColor: 'yellow-gradient',
        color: '#FF5733',
        textColor: '#FFFFFF',
        loc: 'zh-CN',
        input: 'text'
    },
    'Cantonese': {
        messageColor: 'yellow-gradient',
        color: '#FF8C00',
        textColor: '#404258',
        loc: 'zh-HK',
        input: 'text'
    },
    'Spanish': {
        messageColor: 'red-gradient',
        color: '#FFA500',
        textColor: '#404258',
        loc: 'es',
        input: 'latin-prose'
    },
    'English': {
        messageColor: 'blue-gradient',
        color: '#FFFF00',
        textColor: '#404258',
        loc: 'en',
        input: 'latin-prose'
    },
    'Hindi': {
        messageColor: 'orange-gradient',
        color: '#FFFACD',
        textColor: '#8B0000',
        loc: 'hi',
        input: 'text'
    },
    'Bengali': {
        messageColor: 'green-gradient',
        color: '#98FB98',
        textColor: '#404258',
        loc: 'bn',
        input: 'text'
    },
    'Portuguese': {
        messageColor: 'light-green-gradient',
        color: '#008000',
        textColor: '#FFFFFF',
        loc: 'pt',
        input: 'latin-prose'
    },
    'Russian': {
        messageColor: 'dark-blue-gradient',
        color: '#40E0D0',
        textColor: '#FFFFFF',
        loc: 'ru',
        input: 'text'
    },
    'Japanese': {
        messageColor: 'dark-red-gradient',
        color: '#FF1493',
        textColor: '#FFFFFF',
        loc: 'ja',
        input: 'kana'
    },
    'Korean': {
        messageColor: 'dark-red-gradient',
        color: '#3636c2',
        textColor: '#FFFFFF',
        loc: 'ko',
        input: 'hangul'
    },
    'Punjabi': {
        messageColor: 'pink-gradient',
        color: '#4B0082',
        textColor: '#FFFFFF',
        loc: 'pa',
        input: 'text'
    },
    'German': {
        messageColor: 'black-gradient',
        color: '#000000',
        textColor: '#FFFFFF',
        loc: 'de',
        input: 'latin-prose'
    },
    'French': {
        messageColor: 'blue-white-red-gradient',
        color: '#800080',
        textColor: '#FFFFFF',
        loc: 'fr',
        input: 'latin-prose'
    },
    'Thai': {
        messageColor: 'red-white-blue-gradient',
        color: '#FF00FF',
        textColor: '#FFFFFF',
        loc: 'th',
        input: 'text'
    },
    'Dutch': {
        messageColor: 'orange-white-gradient',
        color: '#FFC0CB',
        textColor: '#404258',
        loc: 'nl',
        input: 'latin-prose'
    },
    'Hebrew': {
        messageColor: 'blue-white-star-gradient',
        color: '#EE82EE',
        textColor: '#FFFFFF',
        loc: 'he',
        input: 'text'
    },
    'Filipino': {
        messageColor: 'blue-red-yellow-gradient',
        color: '#E6E6FA',
        textColor: '#404258',
        loc: 'fil',
        input: 'latin-prose'
    },
    'Hungarian': {
        messageColor: 'purple-gradient',
        color: '#EE82EE',
        textColor: '#FFFFFF',
        loc: 'hu',
        input: 'latin-prose'
    },
};
