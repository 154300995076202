import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const OnlineUserItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: black;

    &:last-child {
        border-bottom: none;
    }

    & span {
        display: block;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.6);
    }

    // & span.language {
    //     font-weight: normal;
    //     color: rgba(0, 0, 0, 0.6);
    // }
`;

const InviteButton = styled.button`
  padding: 0.5em 1em;
  background-color: var(--blue-active-color);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const OnlineUsersModal = ({ currentUserId, onlineUsers, onClose, isOpen, onInvite }) => {
    const [invitedUsers, setInvitedUsers] = useState(new Set());
    const { t } = useTranslation();

    const handleInvite = (userId) => {
        setInvitedUsers(prevState => new Set(prevState.add(userId)));
        onInvite(userId);

        // reset the invite after 1 minute .. enables the button again and prevents spamming
        setTimeout(() => {
            setInvitedUsers(prevState => new Set([...prevState].filter(id => id !== userId)));
        }, 60000);
    };

    const filteredUsers = Array.isArray(onlineUsers) ? onlineUsers.filter(user => user.id !== currentUserId) : [];

    return (
        isOpen && (
            <ModalOverlay onClick={onClose}>
                <ModalContent onClick={e => e.stopPropagation()}>
                    {filteredUsers.length > 0 ? 
                        filteredUsers.map(user => (
                            <OnlineUserItem key={user.id}>
                                <span>{user.username} ({user.language})</span>
                                <InviteButton
                                    disabled={invitedUsers.has(user.id)}
                                    onClick={() => handleInvite(user.id)}
                                >
                                    {invitedUsers.has(user.id) ? `${t('OnlineUserInviteSentButton')}` : `${t('OnlineUserInviteButton')}`}
                                </InviteButton>
                            </OnlineUserItem>
                        ))
                        :
                        <p>{t('ChatOnlineUserModalNoUsers')}</p>
                    }
                </ModalContent>
            </ModalOverlay>
        )
    );
};


export default OnlineUsersModal;
