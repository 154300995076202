import { useChat } from "../context/ChatProvider";
import axios from 'axios';

const useChatActions = () => {
    const { socket, user, handleLogout, getRoomLanguages, setIsMessageSending } = useChat();
    
    const inviteToRoom = (invitedUserId, invitedByUserId, invitedByUsername, roomID) => {
        //console.log("emitting 'sendInvite' ..");
        socket.emit('send-room-invite', { 
            invitedUserId: invitedUserId, 
            invitedByUserId: invitedByUserId, 
            invitedByUsername: invitedByUsername, 
            roomId: roomID 
        });
    }

    const sendMessage = (text, roomID) => {
        return new Promise((resolve, reject) => {
            if (!text) {
                return;
            }
    
            //console.log(`Emitting send-message : ${text, roomID, user}`);
            const roomLanguages = getRoomLanguages(roomID);

            socket.emit('send-message', { text, roomID, user, roomLanguages }, (error) => {
                if (error) {
                    //console.log('sending message error');
                    reject(error);
                } else {
                    //resolve();
                    resolve(() => {
                        setIsMessageSending(false);
                    });
                }
            });
        });
    }

    const logout = async () => {
        try{
            await axios.post(`${process.env.REACT_APP_SERVER}/lout`, { withCredentials: true });

            // Emit logout event with user ID for handling logout on server side
            if (user && user.id) {
                socket.emit('logout', user.id);
            }
        }catch(error){
            console.error('Error during logout:', error);
        }finally{
            handleLogout(socket);
        }
    }

    return {
        inviteToRoom,
        sendMessage,
        logout
    }
};

export default useChatActions;