import styled from 'styled-components';

const MessageContainer = ({ incomingMessage, children }) => {
    
    return (
        <StyledMessageContainer
            incomingMessage={incomingMessage}
        >
            {children}  
        </StyledMessageContainer>
    );
};

const StyledMessageContainer = styled.div`
    display: flex;
    cursor: pointer;
    color: #fff;
  //color:var(--blue-gradient);
    font-size: 1rem;
    gap: 5px;
  margin-right:0.3em;
  margin-left:0.3em;
  background:var(--blue-gradient);
  
    // attempting to show users messages on the right, and incoming messages on the left ...
    // this would involve refactoring how we display the message and time stamp .. they need
    // to be seperate so we can just reverse the flex direction ...
    flex-direction: ${props => props.incomingMessage ? 'unset' : 'row-reverse' };
  
`;

export default MessageContainer;