import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { GiExitDoor } from "react-icons/gi"
import { AiFillWechat, AiOutlineUser, AiFillSetting, AiOutlineBars } from "react-icons/ai"
import styled from 'styled-components';
import { ButtonContainer } from '../styled/Button';
import useChatActions from '../hooks/useChatActions';
import { useChat } from '../context/ChatProvider';
import ConfirmationModal from '../styled/ConfirmationModal';
import { useTranslation } from "react-i18next";
import { googleLogout } from '@react-oauth/google'

const Nav = styled.nav`
    display: flex;
    width: 6.75em;
    gap: 5px;
    align-items: center;
    flex-direction: row;
    padding: 5vh 4px;
    //background: #1a1a1a;
    
    & div {
        justify-content: center;
        width: 100%;
    }

    //@media (max-width: 820px) {
    //    width: 100%;
    //    height: 5%;
    //    flex-direction: row;
    //    z-index:50; // should always sit in front of any other views (particularly on mobile)
    //}
`;

const Navigation = ({ openRoomNav }) => {
    const { logout } = useChatActions();
    const { activeView, setActiveView, currentRoom, isNavOpen, setIsNavOpen, user, leaveRoom, isMobile } = useChat();
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    
    const { t } = useTranslation();

    const isGuest = user?.guest;

    const handleLogoutConfirmation = () => {
        setShowLogoutModal(false);
        
        let clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
        
        // Call this function to initiate Google logout
        const gLogout = () => {
            //console.log('Google sign out.');
            googleLogout();
            logout(); 
        };
        
        const emailLogout = () => {
            logout();
        };

        let userLoggedInWithGoogle = sessionStorage.getItem("userLoggedInWithGoogle");
        // Determine logout method based on how the user logged in
        if (userLoggedInWithGoogle === "TRUE") {
            gLogout();
        } else {
            emailLogout();
        }
    }

    const handleChatButtonClick = () => {
        setIsNavOpen(false);
        setActiveView("chat");

        window.scrollTo(0, 0);
    };
    
    const handleConversationListClick = () => {
        setIsNavOpen(true);
        setActiveView("roomNav");

        window.scrollTo(0, 0);
    }

    const handleAccountClick = () => {
        if(currentRoom){
            //setCurrentRoom(null);
            leaveRoom(currentRoom.id);
        }

        setActiveView("account");

        window.scrollTo(0, 0);
    }

    return (
        <Nav>
            {!isGuest && (
                <>
                    <ButtonContainer active={true} onClick={handleConversationListClick}>
                        <Link to="/chat">
                            <AiOutlineBars size='100%' />
                        </Link>    
                    </ButtonContainer>
                    
                    {/*<ButtonContainer active={activeView === "chat"} onClick={handleChatButtonClick}>*/}
                    {/*    <Link to="/chat">  */}
                    {/*        <AiFillWechat size='100%' />*/}
                    {/*    </Link>*/}
                    {/*</ButtonContainer>*/}

                    {/*<ButtonContainer isAvatar active={activeView === "account"} onClick={handleAccountClick}>*/}
                    {/*    <Link to="/account">  */}
                    {/*        <AiFillSetting size='100%' />*/}
                    {/*    </Link>*/}
                    {/*</ButtonContainer>*/}

                </>
            )}
        
            {/*<ButtonContainer onClick={(e) => {*/}
            {/*    e.preventDefault(); */}
            {/*    setShowLogoutModal(true);*/}
            {/*}}>*/}
            {/*    <a href="#">*/}
            {/*        <GiExitDoor size='100%' />*/}
            {/*    </a>*/}
            {/*</ButtonContainer>*/}
            
            {/*{showLogoutModal && <ConfirmationModal */}
            {/*    onClose={() => setShowLogoutModal(false)}*/}
            {/*    onConfirm={handleLogoutConfirmation}*/}
            {/*    message={t('LogoutModalText')} */}
            {/*/>}*/}

        </Nav>
    );
};

export default Navigation;