import React from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MembershipContainer = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(243, 243, 243);
    padding: 20px;

    & p {
        margin-bottom: 1em;
    }
`;

const UpgradeButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;
  height: 2em;
  width:4.8rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    display: flex;
    align-items: center; 
    justify-content: space-between;
`;

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const renderQuota = (sent, quota) => {
    if(sent > quota) 
        sent = quota;
    
    return `${sent}/${quota}`;
};

const renderSubStatus = (user) => {
    if(user.subscriptionCancelled) {
        return `Subscriber (cancelled)`;
    }else{
        return "Subscriber";
    }
}

const redirectToStripeCheckout = (userId, userEmail) => {
    let locale = localStorage.getItem('i18nextLng');
    window.location.href = `${process.env.REACT_APP_STRIPE_PAYMENT_URL}?client_reference_id=${userId}&prefilled_email=${userEmail}&locale=${locale}`;
};

const SubscriptionStatus = ({user, onCancelSub}) => {

    const { t } = useTranslation();
    
    return(
        <MembershipContainer>
            {user.subscriber ? (
                <>
                    <TableRow>
                        <TableColumn>{t("SubformMemStatus")}:</TableColumn>
                        <TableColumn><span><strong>{renderSubStatus(user)}</strong></span></TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn>{t("SubformSince")}</TableColumn>
                        <TableColumn>{formatDate(user.subscribedOn)}</TableColumn>
                    </TableRow>
                    {
                     user.subscriptionCancelled &&
                        <TableRow>
                            <TableColumn>Ends:</TableColumn>
                            <TableColumn>{formatDate(user.subscriptionPeriodEnd)}</TableColumn>
                        </TableRow>
                    }
                    <TableRow>
                        <TableColumn colspan="2">
                            { !user.subscriptionCancelled && <UpgradeButton onClick={onCancelSub}>{t("CancelButton")}</UpgradeButton>} 
                        </TableColumn>
                    </TableRow>
                </>
            ) : (
                <>
                    <TableRow>
                        <TableColumn>{t("SubformMemStatus")}:</TableColumn>
                        <TableColumn><span>{t("SubformFreeTrial")}</span> <UpgradeButton onClick={() => redirectToStripeCheckout(user.id, user.email)}>{t("SubformUpgrade")}</UpgradeButton></TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn>Messages Sent:</TableColumn>
                        <TableColumn>{renderQuota(user.messagesSent, user.messageQuota)}</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn colspan="2">&nbsp;</TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn colspan="2">{t("SubformDesc")}</TableColumn>
                    </TableRow>
                </>
            )}
        </MembershipContainer>
    );
};

export default SubscriptionStatus;