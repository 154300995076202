import React, { useState } from 'react';
import styled from 'styled-components';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const PasswordInputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const PasswordToggleIcon = styled.span`
    position: absolute;
    top: 60%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    color:lightgray;
    font-size: 1.5rem;
`;

const PasswordField = ({ placeholder, reference, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <PasswordInputContainer>
            <Input 
                type={showPassword ? "text" : "password"} 
                placeholder={placeholder} 
                ref={reference} 
                {...rest}
            />
            <PasswordToggleIcon onClick={togglePasswordVisibility}>
                {showPassword ? <MdVisibilityOff size={24} /> : <MdVisibility size={24} />}
            </PasswordToggleIcon>
        </PasswordInputContainer>
    );
};

export default PasswordField;
