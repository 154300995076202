import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
        order: ['localStorage', 'cookie', 'querystring', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        //lookupSessionStorage: 'i18nextLng',
        caches: ['localStorage', 'cookie'],
        // Custom lookup function to handle complex language codes
        lookup: (options) => {
          let found = options.services.languageUtils.getBestMatchFromCodes(options.detectedLngs);
          // Simplify language codes (e.g., "en-US" to "en")
          if (found) {
            return found.split('-')[0];
          }
          return null;
        },
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    fallbackLng: "en",
    //preload: ['en', 'ja', 'de', 'ko', 'es', 'fil', 'fr', 'he', 'hi', 'bn', 'hu', 'nl', 'pa', 'pt', 'ru', 'th', 'zh-CN', 'zh-HK'],
    debug: false,
    interpolation: {
      escapeValue: false, 
    },
    react: {
      useSuspense: true,
    },
    ns: ["translation"],
    defaultNS: "translation",
  });

export default i18n;
