import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import Wrapper from './Wrapper';
import { Description } from '../styled/Description';
import Avatar from "./Avatar";
import FeedbackForm from "./FeedbackForm";
import ModalAlert from "./ModalAlert";
import { useTranslation } from "react-i18next";
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { PasswordInputContainer, PasswordToggleIcon } from '../styled/PasswordInput';
import { fetchLanguages } from "../context/LanguageUtility";
import SubscriptionStatus from "./SubscriptionStatus";
import {useChat} from "../context/ChatProvider";
import {ButtonContainer} from "../styled/Button";
import { Link } from 'react-router-dom';
import { AiOutlineBars, AiFillWechat } from "react-icons/ai"

const SignupContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 1.5vw;
    flex-direction: column;
    height: 100%;
    margin: auto 0;
    //padding: 3vw 1vw;
    overflow-y: auto;
    

    @media (max-width: 820px) {
        height: 70%;
    }
`;

const FormContainer = styled.div`
    padding: var(--vertical-padding) var(--vertical-padding) 1.5vh var(--vertical-padding);
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 70%;
  background:var(--blue-gradient);  
  //background: #fff;
    //border-radius: 30px;
    overflow-y: auto;
    //margin-top: 1em;

    // @media (max-width: 820px) {
    //     margin: 0 2.5vw;
    // }

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        //background: var(--blue-active-color);
        //transition: .3s ease-in-out opacity, box-shadow;
        background-color:#007BFF;
        cursor: pointer;

        &:hover {
            //opacity: 0.85;
            //box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
          background-color: #0056b3;
        }
    }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    gap: 1.2em;
    border-bottom: 1px solid #5f6368;
    padding-bottom: 1em;
    width:100%;
  color:white;
    //height: 3.2em;
    
    // & img {
    //     height: 100%;
    //     border-radius: 0.7em;
    // }

    & h2 {
        font-size: 0.85em;
        font-weight: 600;
    }
  
   & p {
     color:white;
   }
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;

    &::placeholder {
        color: #7b7b7b;
    }
`;

const Select = styled.select`
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.7em;
    border-radius: 5px;
    background: #fafafa;
    color: #424242;
  -webkit-appearance: none;

  @media (max-width: 768px) {
    padding: 1.1em; // Increase padding for mobile
  }
  
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background-color: #f3f3f3;
  margin-top: 1em;
  //margin: 50px 0px 0px 0px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;  // White text
  font-weight: bold;  // Bold text
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: #FF5C5C;  // Reddish color
    margin: 0;  // Prevents changing the size of the form
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // make sure it's in front of other elements
`;

const Spinner = styled.div`
    border: 3px solid rgba(235, 235, 235, 0.3);
    border-radius: 50%;
    border-top: 3px solid #323232;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const HeaderDetails = styled.div`
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    gap:0.5em;
    width:46em;

  @media (max-width: 820px) {
    width:21em;
  }
`;

const AccountPage = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false); 
  const [userData, setUserData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '', isSuccess: false });
  const [showPassword, setShowPassword] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [languageConfiguration, setLanguageConfiguration] = useState(null);
  const { user, setUser, setBlockSending, setIsNavOpen, setActiveView } = useChat();
    
  const { t } = useTranslation();
  
  useEffect(() => {
    // Fetch the current user's details from an endpoint and set them as default values
    async function fetchUserData() {
        try {
            // no need to pass values as jwt cookie will container authenticated user
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/account`,  { withCredentials: true }); 

            if(response.data){
                setUserData(response.data);
                setValue("language", response.data.language);
                
                // update subscriber status for case where this is coming back from a stripe payment form
                user.subscriber = response.data.subscriber;
                setUser(user);
                // in case we previously blocked sending in this session, undo it as they are now subscribers
                if(user.subscriber) setBlockSending(false); 
            }

        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    }

    setIsLoading(true);
    fetchLanguages(setSupportedLanguages, setLanguageConfiguration);
    fetchUserData();
    setIsLoading(false);
    
  }, []);

  // duplication, but we need this so we can refresh after cancelling subscription ..
  const refreshAccountData = async() => {
      try {
          // no need to pass values as jwt cookie will container authenticated user
          const response = await axios.get(`${process.env.REACT_APP_SERVER}/account`,  { withCredentials: true });

          if(response.data){
              setUserData(response.data);
              setValue("language", response.data.language);

              // update subscriber status for case where this is coming back from a stripe payment form
              user.subscriber = response.data.subscriber;
              setUser(user);
              // in case we previously blocked sending in this session, undo it as they are now subscribers
              if(user.subscriber) setBlockSending(false);
          }

      } catch (error) {
          console.error("Error fetching user details:", error);
      }
  };
  
  const handleCancelSub = async () => {
    
    setIsLoading(true);  
      
    try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/sub-cancel`,{}, { withCredentials: true });

        if(response.data.success){
            setModalContent({ title: `Cancelled`, message: `Your subscription has been successfully cancelled.`, isSuccess: true });
        }else{
            setModalContent({ title: `Failed`, message: `${t('RequestFailed')}`, isSuccess: false });
        }
    } catch (error) {
        console.log(error);
        setModalContent({ title: `Failed`, message: `${t('RequestFailed')}`, isSuccess: false });
    }
    
    setIsLoading(false);
    setIsModalOpen(true);
  };
  
  const onSubmit = async (data) => {
    const { password, language } = data;
    let payload = { language };

    if(password) { 
        payload.password = password;
    }

    setIsLoading(true); 

    try { 
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/account`, payload, { withCredentials: true });
        
        if (response.data.success) {
            setModalContent({ title: `${t('SuccessTitle')}`, message: `${t('AccountUpdatedSuccess')}`, isSuccess: true });
            setValue("password", "");
        } else {
            setModalContent({ title: 'Failed', message: `${t('RequestFailed')}`, isSuccess: false });
        }
    } catch (error) {
        setModalContent({ title: 'Failed', message: `${t('RequestFailed')}`, isSuccess: false });
    }

    setIsModalOpen(true);
    setIsLoading(false); 
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

    const handleConversationListClick = () => {
        setIsNavOpen(true);
        setActiveView("chat");
    
        window.scrollTo(0, 0);
    }

  return (
    <Wrapper restrictGuest={true}>
        <SignupContainer>
            <FormContainer>

            {isLoading && 
                <SpinnerOverlay>
                    <Spinner />
                </SpinnerOverlay>
            }

            <Header>
                <HeaderDetails>
                    {userData.avatar && <Avatar src={userData.avatar} size='3.5em' />}
                    <div>
                        <h2>{t('AccountTitle')}</h2>
                        <Description color='#000' size='0.75em'>{ userData.username }</Description>
                        <Description color='#000' size='0.75em'>{ userData.email }</Description>
                    </div>
                </HeaderDetails>
                
                <ButtonContainer active={true} onClick={handleConversationListClick}>
                    <Link to="/chat">
                        <AiFillWechat size='100%' />
                    </Link>
                </ButtonContainer>
            </Header>
                
            <SubscriptionStatus user={userData} onCancelSub={handleCancelSub} />    

            <Form onSubmit={handleSubmit(onSubmit)}>

                <p>{t('AccountPagePassword')}</p>
                {/* <Input
                    {...register("password", {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]{7,}$/ 
                    })}
                    type="password"
                    placeholder=""
                /> */}
                <PasswordInputContainer>
                    <Input 
                        type={showPassword ? "text" : "password"} 
                        placeholder="" 
                        maxLength={50}
                        {...register("password", {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{7,}$/
                        })}
                    />
                    <PasswordToggleIcon onClick={togglePasswordVisibility}>
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </PasswordToggleIcon>
                </PasswordInputContainer>
                {errors.password && <ErrorText>Password must:<br/>- be at least 7 characters long<br/>- contain at least one upper case letter <br/>- one lower case letter <br/>- contain a number</ErrorText>}

                <p>{t('AccountPageLanguageChange')}</p>
                <Select
                    {...register("language", { required: true })}
                >
                    {supportedLanguages.map((language, index) => (
                        <option key={index} value={language}>
                            {t(language)}
                        </option>
                    ))}
                </Select>
                {errors.language && <ErrorText>{t('SignupLanguageValidate')}</ErrorText>}

                <Button type="submit">
                {t('SaveChangesButton')}
                </Button>
                
            </Form>

            <ModalAlert
                isOpen={isModalOpen} 
                onClose={() => {
                        setIsModalOpen(false);
                        refreshAccountData();
                    } 
                }
                title={modalContent.title} 
                message={modalContent.message}
                isSuccess={modalContent.isSuccess} 
            />

            <FeedbackForm />

            </FormContainer>
        </SignupContainer>
    </Wrapper>
    );
}

export default AccountPage;
